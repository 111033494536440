import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { Button } from "semantic-ui-react";

import CustomRichTextEditor from "components/templates/Editor";

import registrationService from "services/registrationService";

import { IOrganisation } from "model/organisation";
import { IRegistrationTexts } from "model/registration";

import "scss/AdminPanel.scss";

interface ILandingPage {
    organisation: IOrganisation;
    setRegistrationTexts: (registrationTexts: IRegistrationTexts) => void;
}

type RegistrationTextFormValues = Partial<IRegistrationTexts>;

export default function LandingPage({ organisation, setRegistrationTexts }: ILandingPage) {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const { handleSubmit, control } = useForm<any>({
        defaultValues: {
            whatHappens: organisation.registrationTexts?.whatHappens || "",
            deliveryKit: organisation.registrationTexts?.deliveryKit || "",
            payment: organisation.registrationTexts?.payment || "",
            consent: organisation.registrationTexts?.consent || "",
        },
    });

    const onSubmit = async (data: RegistrationTextFormValues) => {
        const transformedEmptyFieldData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                const amendedValue = value.trim() === "<p><br></p>" ? "" : value;
                return [key, amendedValue];
            })
        );
        try {
            const response = await registrationService.createRegistrationTexts(
                organisation.uuid,
                transformedEmptyFieldData
            );
            setIsEditMode(false);
            const newRegistrationTexts = {
                ...organisation.registrationTexts,
                ...response.data,
            };
            setRegistrationTexts(newRegistrationTexts);
        } catch (error) {
            console.error("Error updating registration texts", error);
        }
    };

    const isRemote = organisation.codeName && !organisation.allowHomeInitiatedCases;

    const handleChangeEditMode = (): void => {
        setIsEditMode(!isEditMode);
    };

    return (
        <div className="landing-page">
            <div>
                <h2>Landing page</h2>
                <Button onClick={handleChangeEditMode}>{isEditMode ? "Cancel" : "Edit"}</Button>
            </div>
            <form>
                <section>
                    <h3>What happens next</h3>
                    <Controller
                        name="whatHappens"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <CustomRichTextEditor
                                name="whatHappens"
                                value={value}
                                onChange={(content: string) => onChange(content)}
                                readOnly={!isEditMode}
                            />
                        )}
                    />
                </section>
                {isRemote && (
                    <>
                        <section>
                            <h3>Kit delivery</h3>
                            <Controller
                                name="deliveryKit"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <CustomRichTextEditor
                                        name="deliveryKit"
                                        value={value}
                                        onChange={(content: string) => onChange(content)}
                                        readOnly={!isEditMode}
                                    />
                                )}
                            />
                        </section>
                        <section>
                            <h3>Payment</h3>
                            <Controller
                                name="payment"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <CustomRichTextEditor
                                        name="payment"
                                        value={value}
                                        onChange={(content: string) => onChange(content)}
                                        readOnly={!isEditMode}
                                    />
                                )}
                            />
                        </section>
                        <section>
                            <h3>Consent</h3>
                            <Controller
                                name="consent"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        name="consent"
                                        value={value || ""}
                                        onChange={onChange}
                                        multiline
                                        rows={2}
                                        fullWidth
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: !isEditMode,
                                        }}
                                    />
                                )}
                            />
                        </section>
                    </>
                )}
            </form>
            {isEditMode && (
                <div className="button-container">
                    <Button style={{ marginRight: "10px" }} onClick={() => setIsEditMode(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)}>Update</Button>
                </div>
            )}
        </div>
    );
}
