import { Component, Dispatch, SetStateAction, SyntheticEvent, useContext } from "react";
import { Divider, FormProps, Grid, Header, Message } from "semantic-ui-react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import AddNotesModal from "components/CaseDescription/Review/AddNotesModal";
import ReviewNote from "components/CaseDescription/Review/ReviewNote";
import ClinicalNotes from "components/CaseDescription/Review/ClinicalNotes";
import CaseReviewForm from "components/CaseDescription/Review/CaseReviewForm";
import useReviewForm from "components/CaseDescription/Review/hooks/useReviewForm";
import CustomTooltip from "components/templates/CustomTooltip";
import ReallocatedCaseDialog from "components/templates/dialog/ReallocatedCaseDialog";

import { CaseListContext } from "contextProviders/CaseListProvider";
import { CaseReviewContext, ICasesReviewContext } from "contextProviders/CaseReviewProvider";
import { ModalContext } from "contextProviders/ModalProvider";

import { ERROR_FIELD_IS_REQUIRED } from "helpers/common";
import { getUniqueManagementOutcomeLesionReviews } from "helpers/review";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { ICase, ILesion, IReview } from "model/case";
import { CaseViewMode } from "model/caseViewMode";
import { IManagementOptions, IReviewDiagnosis, IReviewEscalation } from "model/organisation";
import { ICreateReviewResponse, IReviewFormData, NoteType } from "model/reviewNotes";
import { WithModalParameters } from "model/modal";
import { CaseStatus } from "model/caseStatus";

import { history } from "App";
import { HOME } from "navigation/routes";

import * as caseService from "services/caseService";
import userService from "services/userService";

import "scss/CaseDetails.scss";
import "scss/Table.scss";

const CHECK_USER_REALLOCATED_TIME = 30000;

interface ICaseReviewProps {
    mode: CaseViewMode;
    reviewDiagnoses: IReviewDiagnosis[];
    casesReviewContext: ICasesReviewContext;
    setModalParameters: (value: WithModalParameters) => void;
    reviewManagementOptions: IManagementOptions[];
    escalationPlan?: IReviewEscalation[];
    updateCaseStatus: (uuid: string, newStatus: CaseStatus) => void;
    updateReviewedCasesTab: (sortByCreationDate?: string) => void;
    updateCaseNotes: (type: string, note: string, noteUuid?: string, organisationUuid?: string) => void;
    removeCaseNote: (type: string, noteUuid: string) => void;
    formData: IReviewFormData;
    setFormData: Dispatch<SetStateAction<IReviewFormData>>;
    submitReview: ({
        reviewDiagnoses,
        reviewManagementOptions,
        escalationPlan,
    }: {
        reviewDiagnoses: IReviewDiagnosis[];
        reviewManagementOptions: IManagementOptions[];
        escalationPlan?: IReviewEscalation[] | undefined;
    }) => Promise<ICreateReviewResponse>;
    validateReviewValues: (
        updatedFormData: IReviewFormData,
        nonSkinCancer?: boolean,
        escalationPlan?: IReviewEscalation[]
    ) => boolean;
    adminNextSteps: IAdminNextStepQuestion[];
    setAdminNextSteps: Dispatch<SetStateAction<IAdminNextStepQuestion[]>>;
}

export interface ILesionReview {
    lesionUuid: string;
    reviewDiagnosisUuid: string;
    reviewManagementUuid: string;
}

export interface ILesionItemReview {
    lesionUuid: string;
    selectedDiagnosesFreeText: string;
    selectedManagementFreeText: string;
    selectedDiagnoses: string;
    selectedManagement: string;
    selectedEscalation: string;
    selectedEscalationFreeText: string;
}

interface ICaseReviewState {
    currentCase: ICase;
    currentUserUuid: string;
    errorDetails: any[];
    reallocatedCase: boolean;
    intervalId: number;
}

class CaseReview extends Component<ICaseReviewProps, ICaseReviewState> {
    constructor(props: ICaseReviewProps) {
        super(props);
        const { casesReviewContext } = this.props;
        const { currentCase } = casesReviewContext;

        this.state = {
            currentCase,
            currentUserUuid: userService.getCurrentUserUuid(),
            errorDetails: [],
            reallocatedCase: false,
            intervalId: 0,
        };
        this.getErrorMessages = this.getErrorMessages.bind(this);
        this.updateAdminNextSteps = this.updateAdminNextSteps.bind(this);
        this.validateAdminNextSteps = this.validateAdminNextSteps.bind(this);
    }

    public componentDidMount() {
        const { mode, reviewDiagnoses } = this.props;
        if (mode === CaseViewMode.REVIEW && reviewDiagnoses) {
            this.startReallocateReviewMonitor();
        }
    }

    private getErrorMessages() {
        const { errorDetails } = this.state;

        return errorDetails.map((detail: any) => (
            <Message key={detail.message} negative>
                <p>{detail.message}</p>
            </Message>
        ));
    }

    private redirectToHome = () => {
        history.push(HOME);
    };

    private generateLesions = () => {
        const {
            casesReviewContext,
            reviewDiagnoses,
            reviewManagementOptions,
            escalationPlan,
            formData: { lesionReviews },
        } = this.props;
        const { currentCase } = casesReviewContext;
        const { lesions } = currentCase;
        const lesionUuids = Object.keys(lesionReviews);
        const currentUserFullName = userService.getCaseReviewerFullName();

        const updatedLesions = lesionUuids.map((lesionUuid, index) => {
            const currentLesion: ILesion = lesions.find((lesion) => lesion.uuid === lesionUuid);
            const currentReview = lesionReviews[lesionUuid];
            const {
                selectedDiagnoses: reviewDiagnosisUuid,
                selectedManagement: reviewManagementUuid,
                selectedDiagnosesFreeText: diagnosisAdditionalText,
                selectedManagementFreeText: managementAdditionalText,
                selectedEscalation,
                selectedEscalationFreeText,
            } = currentReview;
            const currentDiagnosis = reviewDiagnoses.find(
                (reviewDiagnos) => reviewDiagnos.uuid === reviewDiagnosisUuid
            )?.diagnosis;
            const currentManagementOutcome = reviewManagementOptions.find(
                (managementOption) => managementOption.uuid === reviewManagementUuid
            )?.management;
            const currentEscalation = escalationPlan?.find(
                (escalation) => escalation.uuid === selectedEscalation
            )?.escalationPlan;
            const reviewUuid = `${lesionUuid}${index}`;
            const review = {
                createdByName: currentUserFullName,
                reviewDiagnosis: currentDiagnosis,
                diagnosisAdditionalText,
                managementAdditionalText,
                reviewDiagnosisUuid,
                reviewManagement: currentManagementOutcome,
                reviewManagementUuid,
                uuid: reviewUuid,
                escalationPlan: currentEscalation,
                escalationPlanUuid: selectedEscalation,
                escalationPlanAdditionalText: selectedEscalationFreeText,
            } as IReview;
            if (currentLesion.reviews) {
                const reviewIndex = currentLesion.reviews.findIndex((reviewItem) => reviewItem.uuid === reviewUuid);
                const isReviewEdited = reviewIndex !== -1;
                if (isReviewEdited) {
                    currentLesion.reviews.splice(reviewIndex, 1, review);
                } else {
                    currentLesion.reviews.push(review);
                }
            } else {
                currentLesion.reviews = [review];
            }
            return currentLesion;
        });

        return updatedLesions;
    };

    private handleSubmitReview = () => {
        const { reviewManagementOptions, reviewDiagnoses, escalationPlan, submitReview } = this.props;
        const { intervalId } = this.state;

        clearInterval(intervalId);
        return submitReview({
            reviewDiagnoses,
            reviewManagementOptions,
            escalationPlan,
        });
    };

    private getManagementOutcome = () => {
        const {
            reviewManagementOptions,
            formData: { caseManagementOutcome, showCaseManagementOutcome, lesionReviews },
        } = this.props;

        const uniqueManagementOutcomeLesionReview = getUniqueManagementOutcomeLesionReviews(lesionReviews);
        const managementOutcomeUuid = showCaseManagementOutcome
            ? caseManagementOutcome
            : uniqueManagementOutcomeLesionReview[0].selectedManagement;
        const currentManagementOutcome = reviewManagementOptions.find(
            (option) => option.uuid === managementOutcomeUuid
        );
        return currentManagementOutcome?.management;
    };

    private showConfirmModal = () => {
        const {
            setModalParameters,
            casesReviewContext,
            updateCaseStatus: updateCaseListItemStatus,
            updateCaseNotes,
            updateReviewedCasesTab,
            adminNextSteps,
        } = this.props;
        const { updateCaseStatus, currentCase } = casesReviewContext;
        const { uuid, caseId, patient, remote, patientUuid } = currentCase;
        const lesions = this.generateLesions();
        const managementOutcome = this.getManagementOutcome();

        setModalParameters({
            content: (
                <AddNotesModal
                    caseId={caseId}
                    patient={patient}
                    patientUuid={patientUuid}
                    type={NoteType.PATIENT}
                    caseUuid={uuid}
                    updateCaseStatus={updateCaseStatus}
                    updateReviewedCasesTab={updateReviewedCasesTab}
                    callback={this.handleSubmitReview}
                    lesions={lesions}
                    managementOutcome={managementOutcome}
                    clinicalNotes={currentCase.clinicalNotes}
                    updateCaseListItemStatus={updateCaseListItemStatus}
                    remote={remote}
                    updateCaseNotes={updateCaseNotes}
                    administrativeNextSteps={adminNextSteps}
                />
            ),
            isVisible: true,
            size: "small",
        });
    };

    private updateAdminNextSteps(_e: SyntheticEvent<HTMLElement>, data: FormProps): void {
        const { adminNextSteps, setAdminNextSteps } = this.props;
        const { id, value } = data;

        const existingQuestion = adminNextSteps.find((question) => question.questionUuid === id);
        const updatedQuestion = { ...existingQuestion };
        updatedQuestion.error = undefined;
        updatedQuestion.answer = value;

        const updatedQuestions = adminNextSteps.map((question) => {
            if (question.questionUuid === updatedQuestion.questionUuid) {
                return updatedQuestion;
            }
            return question;
        });

        setAdminNextSteps(updatedQuestions);
    }

    private validateAdminNextSteps(): boolean {
        const { adminNextSteps, setAdminNextSteps } = this.props;
        let isError = false;
        const validatedAdminNextStepQuestions = adminNextSteps.map((question) => {
            if (question.required && (!question.answer || question.answer.length === 0)) {
                isError = true;
                const updatedQuestion = { ...question };
                updatedQuestion.error = { content: ERROR_FIELD_IS_REQUIRED, position: "above" };
                return updatedQuestion;
            }
            return question;
        });

        if (isError) {
            setAdminNextSteps(validatedAdminNextStepQuestions);
        }

        return isError;
    }

    private startReallocateReviewMonitor() {
        const { currentCase, currentUserUuid } = this.state;
        const intervalId = window.setInterval(() => {
            caseService.getCaseAsync(currentCase.uuid, ["assignment"]).then((res: ICase) => {
                if (!(res instanceof Error) && res.assignment !== currentUserUuid) {
                    this.setState({ reallocatedCase: true });
                    clearInterval(intervalId);
                }
            });
        }, CHECK_USER_REALLOCATED_TIME);

        this.setState({ intervalId });
    }

    public render() {
        const {
            casesReviewContext,
            reviewManagementOptions,
            mode,
            reviewDiagnoses,
            escalationPlan,
            formData,
            updateCaseNotes,
            removeCaseNote,
            setFormData,
            validateReviewValues,
            adminNextSteps,
        } = this.props;
        const { currentCase } = casesReviewContext;
        const { uuid, patientUuid, patientNotes, clinicalNotes, remote: isRemote, audit } = currentCase;
        const { reallocatedCase } = this.state;
        const { isValid } = formData;
        const errors = this.getErrorMessages();
        const hasAuditRoutingApplied =
            !!audit?.auditSetUuid && !!audit?.addedToAuditSetDate && !!audit?.sentForAuditDate;

        if (mode === CaseViewMode.REVIEW && reviewDiagnoses) {
            return (
                <div className="case-review-wrapper">
                    <ReallocatedCaseDialog showDialog={reallocatedCase} onClose={this.redirectToHome} />
                    <div className="case-review-wrapper__header-wrapper">
                        <Header as="h1" className="without-bottom-margin">
                            Record Your Assessment
                        </Header>
                        {hasAuditRoutingApplied ? (
                            <div className="case-review-wrapper__tooltip-wrapper--right-align">
                                <p className="subtitle-gray-text">Why am I reviewing this case?</p>

                                <CustomTooltip
                                    style={{ fill: "#808080", marginLeft: "8px" }}
                                    placement="bottom-end"
                                    title="This case was not automatically discharged given the Fitzpatrick type."
                                >
                                    <HelpOutlineIcon />
                                </CustomTooltip>
                            </div>
                        ) : null}
                    </div>
                    <Divider className="title-divider" />
                    <CaseReviewForm
                        currentCase={currentCase}
                        reviewDiagnoses={reviewDiagnoses}
                        reviewManagementOptions={reviewManagementOptions}
                        formData={formData}
                        setFormData={setFormData}
                        escalationPlan={escalationPlan}
                        validateValues={validateReviewValues}
                    />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>{errors}</Grid.Column>
                        </Grid.Row>
                        {isRemote ? (
                            <Grid.Row>
                                <Grid.Column>
                                    <ReviewNote
                                        title="Notes for the Patient"
                                        subtitle="These notes will be included in the results shown to the patient and visible to any clinician involved in the assessment"
                                        notes={patientNotes}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        ) : null}
                        <Grid.Row>
                            <Grid.Column>
                                <ClinicalNotes
                                    notes={clinicalNotes}
                                    caseUuid={uuid}
                                    patientUuid={patientUuid}
                                    updateCaseNotes={updateCaseNotes}
                                    removeCaseNote={removeCaseNote}
                                    submitForAssessment={this.showConfirmModal}
                                    isValid={isValid}
                                    caseAssignee={currentCase.assignmentDetails?.assigneeUuid}
                                    administrativeNextStepQuestions={adminNextSteps}
                                    setAdministrativeNextSteps={this.updateAdminNextSteps}
                                    validateAdministrativeNextSteps={this.validateAdminNextSteps}
                                    consentData={currentCase.consentData}
                                    translatorData={currentCase.translatorData}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            );
        }
        return <></>;
    }
}

export function CaseReviewComponent(props: any) {
    const casesReviewContext = useContext(CaseReviewContext);

    const { setModalParameters } = useContext(ModalContext);
    const { updateCaseStatus, updateReviewedCasesTab } = useContext(CaseListContext);
    const { formData, setFormData, submitReview, validateValues, adminNextSteps, setAdminNextSteps } = useReviewForm({
        currentCase: casesReviewContext.currentCase,
        isCaseManagementOutcome: false,
    });

    return (
        <CaseReview
            casesReviewContext={casesReviewContext}
            setModalParameters={setModalParameters}
            updateCaseStatus={updateCaseStatus}
            updateReviewedCasesTab={updateReviewedCasesTab}
            formData={formData}
            setFormData={setFormData}
            submitReview={submitReview}
            validateReviewValues={validateValues}
            adminNextSteps={adminNextSteps}
            setAdminNextSteps={setAdminNextSteps}
            {...props}
        />
    );
}
