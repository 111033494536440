import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PhoneNumberRegistration from "components/MFA/PhoneNumberRegistration";
import InterstitialPage from "components/templates/InterstitialPage";
import VerifyOTP from "components/MFA/VerifyOTP";
import { homeScreenInitialisation } from "services/authorizationService";

import { MFAVerificationChannel } from "model/mfa";

import getMFASetupState from "redux/selectors/mfa";
import * as actions from "redux/actions";

import MobileNumberChanged from "../UserSettings/MobileNumberChanged";
import MobileNumberReverificationInfo from "./MobileNumberReverificationInfo";

const MobileNumberReverification: FC = () => {
    const dispatch = useDispatch();
    const mfaSetupState = useSelector(getMFASetupState);
    const [isMobileNumberUpdated, setIsMobileNumberUpdated] = useState(true);
    const { step } = mfaSetupState;
    const smsVerificationTitle = "Verify your mobile number";

    const homePageInitialisation = () => {
        homeScreenInitialisation(dispatch);
    };

    const handleNext = (): void => {
        dispatch(actions.mfaActions.incrementStep());
    };

    const handleMobileNumberReverification = (): void => {
        setIsMobileNumberUpdated(false);
        dispatch(actions.mfaActions.initMobileNumberReverification());
    };

    return (
        <InterstitialPage>
            {step === 0 ? (
                <MobileNumberReverificationInfo
                    handleMobileNumberUpdate={handleNext}
                    handleMobileNumberReverification={handleMobileNumberReverification}
                />
            ) : null}
            {step === 1 ? <PhoneNumberRegistration /> : null}
            {step === 2 ? (
                <VerifyOTP
                    channel={MFAVerificationChannel.SMS}
                    title={smsVerificationTitle}
                    handleVerification={isMobileNumberUpdated ? handleNext : homePageInitialisation}
                    isMobileNumberUpdated={isMobileNumberUpdated}
                />
            ) : null}
            {step === 3 ? <MobileNumberChanged onClose={homePageInitialisation} /> : null}
        </InterstitialPage>
    );
};

export default MobileNumberReverification;
