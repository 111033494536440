import { Box, Typography } from "@mui/material";
import { FlagImage, ParsedCountry } from "react-international-phone";

interface FlagWithDialCodeProps {
    country?: ParsedCountry;
    displayCountryName?: boolean;
}

const FlagWithCode = ({ country, displayCountryName = false }: FlagWithDialCodeProps) => {
    const { iso2, dialCode, name } = country ?? {};

    return (
        <Box display="flex" alignItems="center">
            <FlagImage iso2={iso2} />
            {displayCountryName && <Typography ml={2}>{name}</Typography>}
            <Typography component="span" display="flex" ml={1}>
                <Box component="span" mr={0.4}>
                    +
                </Box>
                {dialCode}
            </Typography>
        </Box>
    );
};

export default FlagWithCode;
