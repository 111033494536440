import { IMedicalHistoryAnswers } from "model/assessment";
import { PersonalDetailsFields } from "model/atHomeFlow";
import { ConsentGiverType } from "model/case";

export enum AtHomeFlowActionTypes {
    SET_CONSENT = "SET_CONSENT",
    SET_PERSONAL_DETAILS = "SET_PERSONAL_DETAILS",
    SET_FITZPATRICK_QUESTION = "SET_FITZPATRICK_QUESTION",
    SET_MEDICAL_QUESTIONS = "SET_MEDICAL_QUESTIONS",
}

export interface SetConsentAction {
    type: AtHomeFlowActionTypes.SET_CONSENT;
    payload: {
        consent: boolean;
        secondaryConsent: boolean;
        automatedDecisionConsent?: boolean;
        consentGiverType?: ConsentGiverType;
        consentGiverFullName?: string;
        consentGiverRelation?: string;
    };
}

export interface SetPersonalDetailsAction {
    type: AtHomeFlowActionTypes.SET_PERSONAL_DETAILS;
    payload: PersonalDetailsFields;
}

export interface SetFitzpatrickQuestionAction {
    type: AtHomeFlowActionTypes.SET_FITZPATRICK_QUESTION;
    payload: IMedicalHistoryAnswers[];
}

export interface SetMedicalHistoryAction {
    type: AtHomeFlowActionTypes.SET_MEDICAL_QUESTIONS;
    payload: IMedicalHistoryAnswers[];
}

export type AtHomeFlowAction =
    | SetConsentAction
    | SetPersonalDetailsAction
    | SetMedicalHistoryAction
    | SetFitzpatrickQuestionAction;

export default AtHomeFlowAction;
