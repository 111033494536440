import { FC, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxProps, Container, Form, Image, InputOnChangeData, Message } from "semantic-ui-react";
import { assessmentActions } from "redux/actions";
import { getTranslatorData } from "redux/selectors/assessment";

import useAutoLogout from "hooks/useAutoLogout";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";
import CustomButton from "components/templates/Button";

import { ITranslatorData, TranslatorType } from "model/case";
import { IErrorResponse } from "model/errors";

import * as caseService from "services/caseService";

import withModal from "HOC/withModal";

interface ITranslatorDetailsEdit {
    caseUuid: string;
    closeDetails: () => void;
}

const TranslatorDetailsEdit: FC<ITranslatorDetailsEdit> = ({ caseUuid, closeDetails }) => {
    useAutoLogout();
    let translatorData = useSelector(getTranslatorData);
    if (translatorData?.removed) {
        translatorData = undefined;
    }
    const dispatch = useDispatch();
    const [translatorType, setTranslatorType] = useState<TranslatorType | null>(
        translatorData ? translatorData.translatorType : null
    );
    const [languageProfessional, setLanguageProfessional] = useState(
        translatorData?.translatorType === TranslatorType.PROFESSIONAL ? translatorData.language : ""
    );
    const [languageOther, setLanguageOther] = useState(
        translatorData?.translatorType === TranslatorType.OTHER ? translatorData.language : ""
    );
    const [translatorId, setTranslatorId] = useState(translatorData ? translatorData.translatorId : "");
    const [translatorFullName, setTranslatorFullName] = useState(
        translatorData ? translatorData.translatorFullName : ""
    );
    const [translatorRelation, setTranslatorRelation] = useState(
        translatorData ? translatorData.translatorRelation : ""
    );
    const [showValidationError, setShowValidationError] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const validateTranslatorData = () =>
        (translatorType === TranslatorType.PROFESSIONAL && (!translatorId || !languageProfessional)) ||
        (translatorType === TranslatorType.OTHER && (!translatorFullName || !translatorRelation || !languageOther));

    const saveDetails = async (): Promise<void> => {
        if (validateTranslatorData()) {
            setShowValidationError(true);
            return;
        }
        try {
            setApiErrorResponse(null);
            const newTranslatorData: ITranslatorData = {
                translatorType,
                translatorId: translatorType === TranslatorType.PROFESSIONAL ? translatorId : undefined,
                translatorFullName: translatorType === TranslatorType.OTHER ? translatorFullName : undefined,
                translatorRelation: translatorType === TranslatorType.OTHER ? translatorRelation : undefined,
                language: translatorType === TranslatorType.PROFESSIONAL ? languageProfessional : languageOther,
            };
            const result = await caseService.setTranslator(caseUuid, newTranslatorData);
            dispatch(assessmentActions.setTranslatorData({ translatorData: result.data }));
        } catch (error) {
            setApiErrorResponse({
                message: "Setting translator details failed.",
            });
        }
        closeDetails();
    };

    const changeTranslatorType = (event: FormEvent<HTMLInputElement>, obj: CheckboxProps): void => {
        const { value } = obj;
        const typeValue: TranslatorType = TranslatorType[value];
        setTranslatorType(typeValue);
    };

    const onLanguageChange = (event: any, data: InputOnChangeData): void => {
        const { id, value } = data;
        if (id === "languageProfessional") {
            setLanguageProfessional(value);
        } else if (id === "languageOther") {
            setLanguageOther(value);
        }
    };

    const onTranslatorIdChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorId(data.value);
    };

    const onTranslatorFullNameChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorFullName(data.value);
    };

    const onTranslatorRelationChange = (event: any, data: InputOnChangeData): void => {
        setTranslatorRelation(data.value);
    };

    const showTranslatorDataValidationError = (type: string): boolean => {
        switch (type) {
            case "translatorId": {
                return showValidationError && translatorType === TranslatorType.PROFESSIONAL && !translatorId;
            }
            case "languageProfessional": {
                return showValidationError && translatorType === TranslatorType.PROFESSIONAL && !languageProfessional;
            }
            case "translatorFullName": {
                return showValidationError && translatorType === TranslatorType.OTHER && !translatorFullName;
            }
            case "translatorRelation": {
                return showValidationError && translatorType === TranslatorType.OTHER && !translatorRelation;
            }
            case "languageOther": {
                return showValidationError && translatorType === TranslatorType.OTHER && !languageOther;
            }
            default: {
                return false;
            }
        }
    };

    return (
        <Container className="ui segment wizzard-container">
            <Image className="translator-details-edit-icon" src="/images/icons/icon-person-add.svg" />
            <h3 className="translator-details-edit-title">Add translator details</h3>
            <div className="translator-details-edit">
                <Form className="margin-bottom-2">
                    <Form.Radio
                        label="Professional translator provided by the Trust (eg. language line, other professional translation services)"
                        name="translatorType"
                        value="PROFESSIONAL"
                        checked={translatorType === TranslatorType.PROFESSIONAL}
                        onChange={changeTranslatorType}
                    />
                    <Form.Radio
                        label="Other translator (eg. person accompanying the patient, other. Please specify)"
                        name="translatorType"
                        value="OTHER"
                        checked={translatorType === TranslatorType.OTHER}
                        onChange={changeTranslatorType}
                    />
                </Form>
                {translatorType === TranslatorType.PROFESSIONAL ? (
                    <div>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                type="text"
                                value={translatorId}
                                label="Translator ID"
                                id="translatorId"
                                required
                                placeholder="eg. language line ID"
                                onChange={onTranslatorIdChange}
                            />
                        </Form>
                        {showTranslatorDataValidationError("translatorId") && (
                            <Message error content="This field is required" />
                        )}
                        <Form className="margin-bottom-2">
                            <Form.Input
                                type="text"
                                value={languageProfessional}
                                label="Language"
                                required
                                id="languageProfessional"
                                placeholder="Enter language"
                                onChange={onLanguageChange}
                            />
                        </Form>
                        {showTranslatorDataValidationError("languageProfessional") && (
                            <Message error content="This field is required" />
                        )}
                    </div>
                ) : null}
                {translatorType === TranslatorType.OTHER ? (
                    <div>
                        <Form className="margin-bottom-2">
                            <Form.Input
                                type="text"
                                value={translatorFullName}
                                label="Translator name"
                                id="translatorFullName"
                                required
                                placeholder="Enter translator full name"
                                onChange={onTranslatorFullNameChange}
                            />
                        </Form>
                        {showTranslatorDataValidationError("translatorFullName") && (
                            <Message error content="This field is required" />
                        )}
                        <Form className="margin-bottom-2">
                            <Form.Input
                                type="text"
                                value={translatorRelation}
                                label="Relation to the patient"
                                id="translatorRelation"
                                required
                                placeholder="Specify relation to the patient"
                                onChange={onTranslatorRelationChange}
                            />
                        </Form>
                        {showTranslatorDataValidationError("translatorRelation") && (
                            <Message error content="This field is required" />
                        )}
                        <Form className="margin-bottom-2">
                            <Form.Input
                                type="text"
                                value={languageOther}
                                label="Language"
                                required
                                id="languageOther"
                                placeholder="Enter language"
                                onChange={onLanguageChange}
                            />
                        </Form>
                        {showTranslatorDataValidationError("languageOther") && (
                            <Message error content="This field is required" />
                        )}
                    </div>
                ) : null}
                {apiErrorResponse ? (
                    <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
                ) : null}
            </div>
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="cancel" action={closeDetails} text="Cancel" />
                <CustomButton
                    type="button"
                    variant="filled"
                    action={saveDetails}
                    text="Save details"
                    disabled={!translatorType}
                />
            </div>
        </Container>
    );
};

export default withModal(TranslatorDetailsEdit);
