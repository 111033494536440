import { IAssessment } from "model/assessment";
import { ICase, IChaperoneDataWithRemoved, IPatient, ITranslatorDataWithRemoved } from "model/case";

export const getAssessment = (state: any): IAssessment => state.assessment;

export const getPatient = (state: any): IPatient => state.assessment.patient;

export const getCurrentCase = (state: any): ICase => state.assessment.case;

export const getTranslatorData = (state: any): ITranslatorDataWithRemoved => state.assessment?.case?.translatorData;

export const getChaperoneData = (state: any): IChaperoneDataWithRemoved => state.assessment?.case?.chaperoneData;
