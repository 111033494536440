import { FC, ReactNode, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { CountryIso2, defaultCountries, parseCountry } from "react-international-phone";

import CustomPhoneInput from "components/CustomPhoneInput";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { MobileNumberBackendValidationErrorMap, formatMobileNumber, validateMobileNumber } from "helpers/patientUtils";

import { IFormErrorDetails } from "model/formError";
import HttpStatus from "model/httpStatus";
import { MFAButtons, OTP_LENGTH, otpGenericError } from "model/mfa";

import { getPendingRequest } from "redux/selectors/data";

import userService from "services/userService";

import "scss/PhoneNumberRegistration.scss";
import "react-international-phone/style.css";

interface Input {
    countryIso: CountryIso2;
    mobileNumber: string;
}

const DEFAULT_COUNTRIES = defaultCountries.map(parseCountry);
// hack to prevent mui v5 styling being overwritten by mui v4 - Can be removed when we replace replace and uninstall mui v4 from app

const PhoneNumberRegistration: FC = () => {
    const [hasAPIError, setHasAPIError] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<IFormErrorDetails[]>([]);
    const loading = useSelector(getPendingRequest);
    const defaultValues = useMemo(
        () => ({
            defaultValues: {
                mobileNumber: "",
                countryIso: "gb",
            },
        }),
        []
    );

    const {
        clearErrors,
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        setError,
        setValue,
        watch,
    } = useForm<Input>(defaultValues);

    const handleMobileNumberValidation = (): boolean => {
        setValidationError([]);
        clearErrors();
        const mobileNumber = getValues("mobileNumber");
        const { isError, message } = validateMobileNumber({
            mobileNumber,
            validateForMFA: true,
        });
        if (!isError) {
            return true;
        }
        setError("mobileNumber", { message });
        return false;
    };

    const onSubmitInvalid: SubmitErrorHandler<Input> = (): void => {
        handleMobileNumberValidation();
    };

    const onSubmit: SubmitHandler<Input> = async (data: Input): Promise<void> => {
        const { countryIso, mobileNumber } = data;
        const selectedCountry = DEFAULT_COUNTRIES.find((country) => country.iso2 === countryIso);
        const concatenatedMobileNumber = formatMobileNumber(mobileNumber, `+${selectedCountry.dialCode}`);

        try {
            setValidationError(null);
            setHasAPIError(false);
            clearErrors();
            await userService.updateVerificationMobileNumber(concatenatedMobileNumber);
        } catch (error: any) {
            if (
                error?.response?.status === HttpStatus.BAD_REQUEST &&
                error?.response?.data?.errors[0]?.details?.length
            ) {
                setValidationError(error?.response?.data?.errors[0]?.details);
            } else {
                setHasAPIError(true);
            }
        }
    };

    const helperText: ReactNode | string = validationError?.length ? (
        <div>
            {validationError.map((error) => (
                <div>{MobileNumberBackendValidationErrorMap[error?.errorDescription]}</div>
            ))}
        </div>
    ) : (
        ""
    );

    return (
        <Box>
            <h2>Now, enter your mobile number</h2>
            <p>We will send you a {OTP_LENGTH}-digit code to verify your mobile number</p>
            <Box
                className="full-width phone-number-registration-container"
                component="form"
                onSubmit={handleSubmit(onSubmit, onSubmitInvalid)}
            >
                <Box className="phone-number-registration-form">
                    <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: true,
                            onBlur: handleMobileNumberValidation,
                            validate: handleMobileNumberValidation,
                        }}
                        render={({ field }) => (
                            <CustomPhoneInput
                                {...field}
                                phoneNumber={watch("mobileNumber")}
                                countryIso={watch("countryIso")}
                                onChangePhoneNumber={(val) => setValue("mobileNumber", val)}
                                onChangeCountryIso={(val) => setValue("countryIso", val)}
                                errorPhoneNumber={!!errors?.mobileNumber}
                                helperTextPhoneNumber={helperText || errors?.mobileNumber?.message}
                            />
                        )}
                    />
                </Box>
                <Box className="mobile-number-error">
                    {hasAPIError ? (
                        <CustomMessage
                            message={otpGenericError.message}
                            showDefaultHelp
                            type={CustomMessageType.ERROR}
                        />
                    ) : null}
                </Box>
                <Box className="mfa-button-wrapper float-bottom">
                    <Button className="full-width" type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size="16px" /> : MFAButtons.CONTINUE}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default PhoneNumberRegistration;
