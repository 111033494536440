import "react-international-phone/style.css";
import { BaseTextFieldProps, Box, FormHelperText, TextField } from "@mui/material";
import { ChangeEvent, ReactNode } from "react";
import { CountryIso2, defaultCountries, parseCountry } from "react-international-phone";

import { CountryCodeSelect } from "./CountryCode";
import StyledPhoneInputWrapper from "./index.styles";

const DEFAULT_COUNTRIES = defaultCountries.map(parseCountry);

interface CustomPhoneInputProps extends BaseTextFieldProps {
    phoneNumber: string | undefined;
    errorPhoneNumber: boolean;
    helperTextPhoneNumber: ReactNode | string | undefined;
    countryIso: CountryIso2;
    onChangePhoneNumber: (phoneNumber: string) => void;
    onChangeCountryIso: (countryIso: CountryIso2) => void;
}

const onPastedFullNumber = (
    value: string,
    handleChangeCountryIso: (countryIso: CountryIso2) => void,
    handleChangePhoneNumber: (phoneNumber: string) => void
): boolean => {
    const matchingCountry = DEFAULT_COUNTRIES.find((c) => value.startsWith(`+${c.dialCode}`));

    const parsedMatchingCountry = matchingCountry || undefined;

    if (parsedMatchingCountry) {
        const matchedCountryIso = parsedMatchingCountry.iso2;

        const phoneNumberWithoutCode = value.replace(`+${parsedMatchingCountry.dialCode}`, "").trim();

        handleChangeCountryIso(matchedCountryIso);
        handleChangePhoneNumber(phoneNumberWithoutCode);

        return true;
    }
    return false;
};

const CustomPhoneInput = ({
    phoneNumber,
    countryIso,
    errorPhoneNumber,
    helperTextPhoneNumber,
    onChangePhoneNumber,
    onChangeCountryIso,
    ...restProps
}: CustomPhoneInputProps) => {
    const handleChangePhoneNumber = (e: ChangeEvent<HTMLInputElement>) => {
        const trimmedValue = e.target.value.trim();

        const wasPasted = onPastedFullNumber(trimmedValue, onChangeCountryIso, onChangePhoneNumber);

        const onlyDigitsValue = trimmedValue.replace(/\D/g, "");

        if (!wasPasted) {
            onChangePhoneNumber(onlyDigitsValue);
        }
    };

    return (
        <Box>
            <StyledPhoneInputWrapper>
                <Box mr={1}>
                    <CountryCodeSelect label="Country code" countryIso={countryIso} setCountry={onChangeCountryIso} />
                </Box>
                <TextField
                    className="mobile-number-input"
                    fullWidth
                    variant="outlined"
                    label="Mobile number"
                    color="primary"
                    type="tel"
                    value={phoneNumber}
                    error={errorPhoneNumber}
                    onChange={handleChangePhoneNumber}
                    InputLabelProps={{
                        shrink: !!phoneNumber || undefined,
                    }}
                    {...restProps}
                />
            </StyledPhoneInputWrapper>

            {errorPhoneNumber && (
                <FormHelperText sx={{ ml: 1 }} error>
                    {helperTextPhoneNumber}
                </FormHelperText>
            )}
        </Box>
    );
};

export default CustomPhoneInput;
