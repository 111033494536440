import { FC } from "react";
import { List, ListItem } from "semantic-ui-react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ConsentGiverType, IConsentData, ITranslatorData, TranslatorType } from "model/case";

interface IAdditionalAdministrativeDetails {
    consentData?: IConsentData;
    translatorData?: ITranslatorData;
}

const AdditionalAdministrativeDetails: FC<IAdditionalAdministrativeDetails> = ({ consentData, translatorData }) => {
    const getConsentDetails = () =>
        consentData ? (
            <div>
                {consentData.consentGiverType === ConsentGiverType.PATIENT && (
                    <span>Consent was provided by the patient.</span>
                )}
                {consentData.consentGiverType === ConsentGiverType.LPA && (
                    <>
                        <span>Consent was provided by:</span>
                        <List bulleted>
                            <ListItem key="consentGiverFullName">
                                <span>name: {consentData.consentGiverFullName}</span>
                            </ListItem>
                            <ListItem key="consentGiverRelation">
                                <span>relation to the patient: Lasting Power of Attorney</span>
                            </ListItem>
                        </List>
                    </>
                )}
                {consentData.consentGiverType === ConsentGiverType.OTHER && (
                    <>
                        <span>
                            Please note that consent has not been provided by patient or an LPA for health and welfare.
                            Answers to consent statement have been provided by:
                        </span>
                        <List bulleted>
                            <ListItem key="consentGiverFullName">
                                <span>name: {consentData.consentGiverFullName}</span>
                            </ListItem>
                            <ListItem key="consentGiverRelation">
                                <span>relation to the patient: {consentData.consentGiverRelation}</span>
                            </ListItem>
                        </List>
                    </>
                )}
            </div>
        ) : null;

    const getTranslatorDetails = () => {
        const isProfessional = translatorData?.translatorType === TranslatorType.PROFESSIONAL ? "professional " : "";
        return (
            <div className="additional-administrative-details-content-item">
                {!translatorData ? (
                    <span>A translator was not involved.</span>
                ) : (
                    <span>
                        A {isProfessional}translator was involved during the appointment to help with consent or other
                        clinical information
                    </span>
                )}
                {translatorData?.translatorType === TranslatorType.PROFESSIONAL && (
                    <List bulleted>
                        <ListItem key="translatorId">
                            <span>Translator ID: {translatorData.translatorId}</span>
                        </ListItem>
                        <ListItem key="language">
                            <span>Language: {translatorData.language}</span>
                        </ListItem>
                    </List>
                )}
                {translatorData?.translatorType === TranslatorType.OTHER && (
                    <List bulleted>
                        <ListItem key="translatorFullName">
                            <span>Translator name: {translatorData.translatorFullName}</span>
                        </ListItem>
                        <ListItem key="translatorFullName">
                            <span>Relation to the patient: {translatorData.translatorRelation}</span>
                        </ListItem>
                        <ListItem key="language">
                            <span>Language: {translatorData.language}</span>
                        </ListItem>
                    </List>
                )}
            </div>
        );
    };

    const consentDetails = getConsentDetails();
    const translatorDetails = getTranslatorDetails();

    // Translator data management is independent from extended consent data, but it has been agreed to introduce them together and use consent data existence as flag for in flight cases.
    return consentData ? (
        <div>
            <div className="additional-administrative-details">
                <InfoOutlinedIcon className="additional-administrative-details-info-icon" />
                <div className="additional-administrative-details-content">
                    <span>{consentDetails}</span>
                    <span>{translatorDetails}</span>
                </div>
            </div>
        </div>
    ) : null;
};

export default AdditionalAdministrativeDetails;
