import { FC, useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { ReCAPTCHA } from "react-google-recaptcha";

import { history } from "App";

import RemoteModelContainer from "components/templates/RemoteModelContainer";
import AtHomeSubmissionModal from "components/RemoteModel/AtHomeSubmissionModal";
import CaseStatusContainer from "components/RemoteModel/CaseStatusContainer";
import Questionary from "components/Questionary";

import AtHomeFlowContext from "contextProviders/AtHomeFlowProvider";
import { AtHomeFlowActionTypes } from "contextProviders/modules/atHomeFlow/actions";

import { scrollToError } from "helpers/assessment";
import { areRemoteLesionsCompleted } from "helpers/caseList";
import { generateAnswers } from "helpers/questionary";

import useNavigationPrompt from "hooks/useNavigationPrompt";

import { IHistoryAnswerType, IHistoryQuestion, IMedicalHistoryQuestion, IQuestionaryAnswers } from "model/organisation";
import { IMedicalHistoryAnswers } from "model/assessment";
import { RemoteTimeline } from "model/remoteCaseTimeline";
import { AtHomeFlowApiBody, MedicalHistoryApiBodyField, Recaptcha } from "model/atHomeFlow";
import { ModalProps, ModalTypes } from "model/modal";
import NavigationMessages from "model/navigation";
import { IQuestionaryError } from "model/questionaryError";
import IQuestionSet from "model/questionSet";
import QuestionSubTypeEnum from "model/questionSubType";

import {
    REMOTE_MODEL_CASES_KIT_DELIVERY,
    REMOTE_MODEL_CASES_SUMMARY,
    REMOTE_MODEL_CASES_SUN_EXPOSURE,
} from "navigation/remoteModelRoutes";

import { getOrganisation, getPendingRequest } from "redux/selectors/data";
import { getAssessment } from "redux/selectors/assessment";
import { getRegistration } from "redux/selectors/registration";

import * as patientService from "services/patientService";
import registrationService from "services/registrationService";

import "scss/RemoteModel.scss";

const MedicalHistoryQuestions: FC = () => {
    const {
        consent,
        secondaryConsent,
        automatedDecisionConsent,
        consentGiverType,
        consentGiverFullName,
        consentGiverRelation,
        personalDetails,
        dispatch: atHomeFlowDispatch,
    } = useContext(AtHomeFlowContext);

    const [errors, setErrors] = useState<IQuestionaryError>({
        formError: false,
        isInitial: true,
        list: [],
        showError: false,
    });
    const [answers, setAnswers] = useState({});
    const [isEdited, setIsEdited] = useState(false);
    const hasFitzpatrickQuestion: React.MutableRefObject<boolean> = useRef<boolean>(null);

    const recaptchaRef: React.MutableRefObject<ReCAPTCHA> = useRef<ReCAPTCHA>(null);
    const [recaptcha, setRecaptcha] = useState<Recaptcha>({
        token: "",
        errored: false,
        ref: recaptchaRef,
    });

    const [modal, setModal] = useState<Omit<ModalProps, "children">>({
        show: false,
        type: ModalTypes.SUCCESS,
    });

    const organisation = useSelector(getOrganisation);
    const pendingRequest = useSelector(getPendingRequest);
    const assessment = useSelector(getAssessment);
    const registration = useSelector(getRegistration);

    const dispatch = useDispatch();

    let medicalHistoryQuestionsWithoutFitzpatrick: IMedicalHistoryQuestion[];
    const allMedicalHistoryQuestions = organisation.medicalHistoryQuestions.filter(
        (question: IMedicalHistoryQuestion) => !question.removed
    );
    const biopsyQuestions = organisation.biopsyQuestions
        ? organisation.biopsyQuestions.filter((question: IMedicalHistoryQuestion) => !question.removed)
        : [];
    const isAtHomeFlow = registration?.flowType?.allowHomeInitiatedCases;

    if (isAtHomeFlow) {
        medicalHistoryQuestionsWithoutFitzpatrick = allMedicalHistoryQuestions.filter(
            (question: IMedicalHistoryQuestion) => question?.questionSubType !== QuestionSubTypeEnum.SKIN_TYPE
        );
        const fitzpatrickQuestion = allMedicalHistoryQuestions
            .filter((question: IMedicalHistoryQuestion) => question?.questionSubType === QuestionSubTypeEnum.SKIN_TYPE)
            .filter((question: IMedicalHistoryQuestion) => !question?.removed);

        hasFitzpatrickQuestion.current = fitzpatrickQuestion?.length > 0;
    }

    const { preventUserNavigation, setPreventUserNavigation, setReadyToProgress } = useNavigationPrompt({
        predicate: isAtHomeFlow,
        callback: hasFitzpatrickQuestion.current
            ? () => {
                  history.push(REMOTE_MODEL_CASES_SUN_EXPOSURE);
              }
            : null,
    });

    useEffect(() => {
        if (assessment?.patient) {
            const { medicalHistory } = assessment.patient;

            if (medicalHistory) {
                const questions = [...biopsyQuestions, ...allMedicalHistoryQuestions].sort(
                    (a, b) => a.questionOrder - b.questionOrder
                );
                const medicalHistoryAnswers = generateAnswers(medicalHistory, questions);

                setErrors({
                    formError: false,
                    isInitial: false,
                    list: [],
                    showError: true,
                });
                setAnswers(medicalHistoryAnswers);
            }
        }
    }, []);

    const questionSets: IQuestionSet[] = [
        {
            questions: isAtHomeFlow ? medicalHistoryQuestionsWithoutFitzpatrick : allMedicalHistoryQuestions,
        },
        ...(biopsyQuestions.length
            ? [
                  {
                      label: "Biopsy Questions",
                      questions: biopsyQuestions,
                  },
              ]
            : []),
    ];

    const handleRecaptchaChange = (token: string): void => {
        setRecaptcha({
            token,
            errored: false,
            ref: recaptchaRef,
        });
    };

    const submit = async ({ mainData }: { mainData: IMedicalHistoryAnswers[] }) => {
        const isValid = !errors.list.length && !errors.isInitial;

        setErrors({ ...errors, showError: true });

        if (isAtHomeFlow) {
            if (hasFitzpatrickQuestion.current) {
                atHomeFlowDispatch({
                    type: AtHomeFlowActionTypes.SET_MEDICAL_QUESTIONS,
                    payload: mainData,
                });

                setPreventUserNavigation(() => {
                    setReadyToProgress(true);

                    return false;
                });
            } else {
                const token: string = await recaptchaRef.current.executeAsync();
                handleRecaptchaChange(token);
                if (!token) {
                    setRecaptcha({ ...recaptcha, errored: true });
                    recaptchaRef.current?.reset();
                    return;
                }

                atHomeFlowDispatch({
                    type: AtHomeFlowActionTypes.SET_MEDICAL_QUESTIONS,
                    payload: mainData,
                });

                setPreventUserNavigation(() => {
                    setReadyToProgress(true);

                    return false;
                });

                const medicalHistoryAnswers: MedicalHistoryApiBodyField = mainData.map(
                    ({ questionUuid, answers: submittedAnswers, additionalInformation }) => ({
                        questionUuid,
                        answers: submittedAnswers,
                        additionalInformation,
                    })
                );

                try {
                    if (registration?.organisationUuid) {
                        const apiBody: AtHomeFlowApiBody = {
                            organisationUuid: registration?.organisationUuid,
                            consent,
                            secondaryConsent,
                            automatedDecisionConsent,
                            consentGiverType,
                            consentGiverFullName,
                            consentGiverRelation,
                            patientData: personalDetails,
                            medicalHistoryAnswers,
                            recaptchaResponse: token,
                        };

                        await registrationService.submitAtHomeQuestionnaireCase(apiBody);

                        setModal({
                            show: true,
                            type: ModalTypes.SUCCESS,
                        });
                    } else {
                        throw new Error();
                    }
                } catch (err) {
                    setRecaptcha({ ...recaptcha, errored: true });
                    recaptchaRef.current?.reset();
                    setModal({
                        show: true,
                        type: ModalTypes.ERROR,
                    });
                }
            }
        } else if (!pendingRequest && isValid) {
            const {
                case: { lesions },
            } = assessment;
            const nextPage = areRemoteLesionsCompleted(lesions)
                ? REMOTE_MODEL_CASES_SUMMARY
                : REMOTE_MODEL_CASES_KIT_DELIVERY;

            if (isEdited) {
                dispatch(patientService.submitMedicalAnswers(mainData));
            }

            history.push(nextPage);
        }
    };

    const handleAnswersChange = (updatedAnswers: IQuestionaryAnswers) => {
        setAnswers(updatedAnswers);
        if (!isEdited) {
            setIsEdited(true);
        }
    };

    const closeModal = () => {
        setModal((prevState) => ({
            ...prevState,
            show: false,
        }));
    };

    const handleErrors = (questionaryErrors: IQuestionaryError, question: IHistoryQuestion): void => {
        setErrors((prevState) => {
            if (isAtHomeFlow) {
                const { list: errorList, showError } = questionaryErrors;

                if (showError && errorList?.length && question?.answerType !== IHistoryAnswerType.TEXT) {
                    scrollToError({ errorUuid: errorList[0], currentQuestionUuid: question?.uuid });
                }
            }
            return {
                ...prevState,
                ...questionaryErrors,
            };
        });
    };

    return (
        <>
            <Prompt when={preventUserNavigation} message={NavigationMessages.WARN_BEFORE_NAVIGATION} />
            {!isAtHomeFlow && <CaseStatusContainer activeStatus={RemoteTimeline.MEDICAL_HISTORY} />}
            <RemoteModelContainer header="Your Medical History">
                <p>
                    Our dermatologists need you to answer a few questions to prepare for your assessment. Please add
                    your answers below.
                </p>
                <Questionary
                    questionSets={questionSets}
                    errors={errors}
                    updateAnswers={handleAnswersChange}
                    answers={answers}
                    updateErrors={handleErrors}
                    isRemoteModel
                    nextButtons={[
                        {
                            action: submit,
                            isLoading: pendingRequest,
                            isDisabled: pendingRequest,
                            text: isAtHomeFlow && !hasFitzpatrickQuestion.current ? "Submit" : "Continue",
                            type: "submit",
                        },
                    ]}
                    recaptcha={recaptcha}
                />
            </RemoteModelContainer>

            <AtHomeSubmissionModal modal={modal} closeModal={closeModal} />
        </>
    );
};

export default MedicalHistoryQuestions;
