import { FC, useState } from "react";
import { List, ListItem } from "semantic-ui-react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getTranslatorData } from "redux/selectors/assessment";
import { assessmentActions } from "redux/actions";
import { handleKeyDown } from "helpers/input";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { IConsentData, TranslatorType } from "model/case";
import { IErrorResponse } from "model/errors";

import * as caseService from "services/caseService";

import TranslatorDetailsEdit from "./TranslatorDetailsEdit";
import TranslatorDetailsRemovalConfirmation from "./TranslatorDetailsRemovalConfirmation";

interface ITranslatorDetails {
    caseUuid: string;
    consentData?: IConsentData;
}

const TranslatorDetails: FC<ITranslatorDetails> = ({ caseUuid, consentData }) => {
    const translatorData = useSelector(getTranslatorData);
    const dispatch = useDispatch();
    const [showTranslatorDetailsEdit, setShowTranslatorDetailsEdit] = useState(false);
    const [showTranslatorDetailsDelete, setShowTranslatorDetailsDelete] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const deleteDetails = async (): Promise<void> => {
        try {
            setApiErrorResponse(null);
            await caseService.removeTranslator(caseUuid);
            dispatch(assessmentActions.setTranslatorData({ translatorData: undefined }));
        } catch (error) {
            setApiErrorResponse({
                message: "Removing translator details failed.",
            });
        }
        setShowTranslatorDetailsDelete(false);
    };

    const getDetails = (): React.ReactNode => {
        if (!translatorData || translatorData.removed) {
            return (
                <span>
                    Was a translator involved to provide consent or other clinical information? If yes, please add the
                    details
                </span>
            );
        }
        return (
            <div>
                <span>A translator was involved:</span>
                <List bulleted>
                    {translatorData.translatorType === TranslatorType.PROFESSIONAL && (
                        <ListItem key="translatorId">
                            <span>Translator ID: {translatorData.translatorId}</span>
                        </ListItem>
                    )}
                    {translatorData.translatorType === TranslatorType.OTHER && (
                        <>
                            <ListItem key="translatorFullName">
                                <span>Translator name: {translatorData.translatorFullName}</span>
                            </ListItem>
                            <ListItem key="translatorRelation">
                                <span>Relation with the patient: {translatorData.translatorRelation}</span>
                            </ListItem>
                        </>
                    )}
                    {translatorData.translatorType && (
                        <ListItem key="language">
                            <span>Language: {translatorData.language}</span>
                        </ListItem>
                    )}
                </List>
            </div>
        );
    };

    const getActions = (): React.ReactNode => {
        if (!translatorData || translatorData.removed) {
            return (
                <span
                    className="text-button text-button__no-underline translator-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowTranslatorDetailsEdit(true);
                        })
                    }
                    onClick={() => {
                        setShowTranslatorDetailsEdit(true);
                    }}
                >
                    Add details
                </span>
            );
        }
        return (
            <>
                (
                <span
                    className="text-button text-button__no-underline translator-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowTranslatorDetailsEdit(true);
                        })
                    }
                    onClick={() => {
                        setShowTranslatorDetailsEdit(true);
                    }}
                >
                    Edit
                </span>{" "}
                |{" "}
                <span
                    className="text-button text-button__no-underline translator-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowTranslatorDetailsDelete(true);
                        })
                    }
                    onClick={() => {
                        setShowTranslatorDetailsDelete(true);
                    }}
                >
                    Delete
                </span>
                )
            </>
        );
    };

    const translatorDetails = getDetails();
    const translatorActions = getActions();

    // Translator data management is independent from extended consent data, but it has been agreed to introduce them together and use consent data existence as flag for in flight cases.
    return consentData ? (
        <div>
            {apiErrorResponse ? (
                <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
            ) : null}
            <div className="translator-details">
                <InfoOutlinedIcon className="translator-details-info-icon" />
                <span className="translator-details-content">{translatorDetails}</span>
                <span className="translator-details-actions">{translatorActions}</span>
            </div>
            <TranslatorDetailsEdit
                show={showTranslatorDetailsEdit}
                closeOnOverlay={false}
                caseUuid={caseUuid}
                closeDetails={() => setShowTranslatorDetailsEdit(false)}
            />
            <TranslatorDetailsRemovalConfirmation
                show={showTranslatorDetailsDelete}
                closeOnOverlay={false}
                onClose={() => setShowTranslatorDetailsDelete(false)}
                onConfirm={deleteDetails}
            />
        </div>
    ) : null;
};

export default TranslatorDetails;
