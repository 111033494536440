import { useState } from "react";
import { FormControl, InputLabel, SelectProps } from "@mui/material";
import { MenuItem } from "@material-ui/core";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { CountryIso2, defaultCountries, parseCountry } from "react-international-phone";
import StyledCountryCodeSelect from "./index.styles";
import FlagWithCode from "./FlagWithCode";

interface CustomCountryCodeSelectProps {
    countryIso: CountryIso2;
    setCountry: (countryIso: CountryIso2) => void;
}

type CountryCodeSelectProps = CustomCountryCodeSelectProps & SelectProps;

const DEFAULT_COUNTRIES = defaultCountries.map(parseCountry);

export const CountryCodeSelect = ({ countryIso, setCountry, ...rest }: CountryCodeSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = (): void => {
        setIsOpen(true);
    };

    const close = (): void => {
        setIsOpen(false);
    };

    const handleChangeCountryCode = (cIso: CountryIso2) => {
        setCountry(cIso);
        close();
    };

    const country = DEFAULT_COUNTRIES.find((c) => c.iso2 === countryIso);
    const parsedCountry = country ?? undefined;

    const Row = ({ index, style }: ListChildComponentProps) => {
        const pCountry = DEFAULT_COUNTRIES[index];
        const { iso2 } = pCountry;
        const isSelected = iso2 === countryIso;

        return (
            <MenuItem
                tabIndex={0}
                key={iso2}
                value={iso2}
                style={style}
                disableTouchRipple
                disableRipple
                selected={isSelected}
                onClick={() => handleChangeCountryCode(iso2)}
            >
                <FlagWithCode country={pCountry} displayCountryName />
            </MenuItem>
        );
    };

    return (
        <FormControl variant="outlined" error={rest?.error ?? false}>
            {rest?.label && <InputLabel id="country-select-label">{rest.label}</InputLabel>}

            <StyledCountryCodeSelect
                className="9999999"
                labelId="country-select-label"
                id="country-select"
                value={parsedCountry?.iso2}
                open={isOpen}
                onOpen={open}
                onClose={close}
                renderValue={() => <FlagWithCode country={parsedCountry} />}
                {...rest}
            >
                <List height={400} width={320} itemSize={40} itemCount={DEFAULT_COUNTRIES.length}>
                    {Row}
                </List>
            </StyledCountryCodeSelect>
        </FormControl>
    );
};

export default CountryCodeSelect;
