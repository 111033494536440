import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledCountryCodeSelect = styled(Select)(() => ({
    "& .MuiSelect-select": {
        marginRight: "1rem",
    },
    "& svg": {
        marginRight: "0.3rem",
    },
    "height": "53px",
}));

export default StyledCountryCodeSelect;
