import { FC, ReactNode, SyntheticEvent } from "react";
import { Accordion, Form, FormProps, Grid, Icon, List, ListItem, Segment } from "semantic-ui-react";
import Tooltip from "@material-ui/core/Tooltip";

import { formatTimeWithHours } from "helpers/datetime";
import { buildQuestionsHierarchy } from "helpers/administrativeNextSteps";

import { IAdminNextStepQuestion } from "model/administrativeNextSteps";
import { ReviewMode } from "model/caseViewMode";
import { TemplateFieldQuestionType } from "model/organisation";
import { NoteTypeTitle } from "model/reviewNotes";
import { IConsentData, IReview, ITranslatorData } from "model/case";
import AdditionalAdministrativeDetails from "./AdditionalAdministrativeDetails";

interface IAdministrativeNextStepsProps {
    nextStepQuestions: IAdminNextStepQuestion[];
    mode: ReviewMode;
    isOpen: boolean;
    handleOpen: () => void;
    handleChange?: (e: SyntheticEvent<HTMLElement>, data: FormProps) => void;
    caseReviews?: IReview[];
    consentData?: IConsentData;
    translatorData?: ITranslatorData;
}

const AdministrativeNextSteps: FC<IAdministrativeNextStepsProps> = ({
    nextStepQuestions,
    mode,
    isOpen,
    handleOpen,
    handleChange,
    caseReviews,
    consentData,
    translatorData,
}) => {
    const getStyledAnswer = (answer: string | string[]): ReactNode =>
        answer !== "Not answered" ? (
            <span className="answer">{answer}</span>
        ) : (
            <span className="no-answer">{answer}</span>
        );

    const getAnswerSummary = (questions: IAdminNextStepQuestion[]): ReactNode => {
        let title = "N/A";
        if (questions?.length) {
            const caseReview = caseReviews?.find((review) => review.uuid === questions[0].reviewUuid);

            title = caseReview
                ? `Assessed by ${caseReview.createdByName} (${formatTimeWithHours(caseReview.creationDate)})`
                : "N/A";
        }

        const adminNextStepsMap: Map<IAdminNextStepQuestion, IAdminNextStepQuestion[]> =
            buildQuestionsHierarchy(questions);
        return (
            <div className="admin-next-steps-answer-container">
                <AdditionalAdministrativeDetails consentData={consentData} translatorData={translatorData} />
                {adminNextStepsMap.size > 0 && (
                    <>
                        <p className="subtitle-gray-text assessed-by-text">{title}</p>
                        {[...adminNextStepsMap.keys()].map((parent) => (
                            <Segment key={parent.questionUuid} className="admin-next-steps-item">
                                <span className="question">
                                    {parent.question}: {getStyledAnswer(parent.answer)}
                                </span>
                                {adminNextStepsMap.get(parent)?.length ? (
                                    <List bulleted className="child-question-list">
                                        {adminNextStepsMap.get(parent).map((child) => (
                                            <ListItem key={child.questionUuid}>
                                                <span className="question">{child.question}:</span>
                                                {getStyledAnswer(child.answer)}
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : null}
                            </Segment>
                        ))}
                    </>
                )}
            </div>
        );
    };

    const renderQuestion = (
        questionType: string,
        required: boolean,
        questionUuid: string,
        question: string,
        error: any,
        options: string[],
        answer: string | string[],
        handleInputChange: (e: SyntheticEvent<HTMLElement>, data: FormProps) => void
    ): ReactNode => {
        switch (questionType) {
            case TemplateFieldQuestionType.YES_NO:
                return (
                    <Grid.Column width="8" key={questionUuid}>
                        <Form.Select
                            fluid
                            tabIndex={0}
                            required={required}
                            key={questionUuid}
                            id={questionUuid}
                            label={question}
                            placeholder="Yes/No"
                            error={error}
                            onChange={handleInputChange}
                            options={[
                                { key: "yes", text: "Yes", value: "Yes" },
                                { key: "no", text: "No", value: "No" },
                            ]}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                );
            case TemplateFieldQuestionType.DROPDOWN:
                return (
                    <Grid.Column width="8" key={questionUuid}>
                        <Form.Select
                            fluid
                            tabIndex={0}
                            required={required}
                            key={questionUuid}
                            label={question}
                            placeholder="Select from the list"
                            error={error}
                            id={questionUuid}
                            onChange={handleInputChange}
                            options={Object.keys(options).map((key) => ({
                                key,
                                text: options[key],
                                value: options[key],
                            }))}
                            selectOnBlur={false}
                        />
                    </Grid.Column>
                );
            case TemplateFieldQuestionType.MULTIPLE_CHOICE:
                return (
                    <Grid.Column width="8" key={questionUuid}>
                        <Form.Select
                            multiple
                            search
                            tabIndex={0}
                            id={questionUuid}
                            required={required}
                            label={question}
                            key={questionUuid}
                            placeholder="Select all that apply"
                            error={error}
                            onChange={handleInputChange}
                            selectOnBlur={false}
                            options={Object.keys(options).map((key) => ({
                                key,
                                text: options[key],
                                value: options[key],
                            }))}
                        />
                    </Grid.Column>
                );
            case TemplateFieldQuestionType.FREE_TEXT:
                return (
                    <Grid.Column width="8" key={questionUuid}>
                        <Form.TextArea
                            tabIndex={0}
                            rows={1}
                            required={required}
                            key={questionUuid}
                            label={question}
                            id={questionUuid}
                            error={error}
                            placeholder="Type here"
                            onChange={handleInputChange}
                            value={answer as string}
                        />
                    </Grid.Column>
                );
            default:
                return null;
        }
    };

    const getQuestionForm = (questions: IAdminNextStepQuestion[]): ReactNode => {
        const groupedQuestions: { key: string; group: IAdminNextStepQuestion[] }[] = [];
        const questionGroups = new Map();
        questions.forEach((q) => {
            const { questionUuid, parentQuestionUuid } = q;

            const parentQuestionKey = parentQuestionUuid || questionUuid;

            if (!questionGroups.has(parentQuestionKey)) {
                questionGroups.set(parentQuestionKey, []);
            }
            questionGroups.get(parentQuestionKey).push(q);
        });
        questionGroups.forEach((group, key) => {
            groupedQuestions.push({ key, group });
        });

        const renderedQuestions = groupedQuestions.map((questionArray) => {
            const firstRow = questionArray.group.slice(0, 2);
            const additionalRows = questionArray.group.slice(2);
            return (
                <>
                    <Grid.Row key={questionArray.key} verticalAlign="bottom">
                        {firstRow.map(({ questionType, required, questionUuid, question, error, options, answer }) =>
                            renderQuestion(
                                questionType,
                                required,
                                questionUuid,
                                question,
                                error,
                                options,
                                answer,
                                handleChange
                            )
                        )}
                    </Grid.Row>
                    {additionalRows.map(
                        ({ questionType, required, questionUuid, question, error, options, answer }) => (
                            <Grid.Row key={questionArray.key} style={{ justifyContent: "flex-end" }}>
                                {renderQuestion(
                                    questionType,
                                    required,
                                    questionUuid,
                                    question,
                                    error,
                                    options,
                                    answer,
                                    handleChange
                                )}
                            </Grid.Row>
                        )
                    )}
                </>
            );
        });
        return (
            <Form>
                <Grid className="question-group-container">{renderedQuestions}</Grid>
            </Form>
        );
    };

    const getContent = (questions: IAdminNextStepQuestion[]): ReactNode => {
        if (mode === ReviewMode.REVIEW) {
            return (
                <div>
                    <AdditionalAdministrativeDetails consentData={consentData} translatorData={translatorData} />
                    {getQuestionForm(questions)}
                </div>
            );
        }
        return getAnswerSummary(questions);
    };

    return (
        <Accordion className="admin-next-steps">
            <Accordion.Title onKeyPress={handleOpen} onClick={handleOpen} tabIndex={0}>
                {NoteTypeTitle.NEXT_STEPS}
                <Icon name={isOpen ? "chevron up" : "chevron down"} size="small" />
                {mode === ReviewMode.REVIEW ? (
                    <Tooltip
                        title={
                            <p style={{ fontSize: "14px" }}>
                                Your administrative next steps will be visible to any clinician involved in the
                                assessment and will be surfaced in the clinical report
                            </p>
                        }
                        placement="top"
                    >
                        <Icon name="info" size="tiny" bordered />
                    </Tooltip>
                ) : null}
            </Accordion.Title>
            <Accordion.Content active={isOpen}>{getContent(nextStepQuestions)}</Accordion.Content>
        </Accordion>
    );
};

AdministrativeNextSteps.defaultProps = {
    handleChange: undefined,
    caseReviews: [],
};

export default AdministrativeNextSteps;
