import types from "redux/actions/types";

const INITIAL_STATE = {
    assessmentError: undefined,
    captureImageWizard: {
        currentCapturDevice: 1,
        finished: false,
    },
    case: undefined,
    consent: undefined,
    secondaryConsent: undefined,
    automatedDecisionConsent: undefined,
    continueAssessment: false,
    currentLesion: 0,
    devices: undefined,
    diagnosis: undefined,
    lesion: undefined,
    locationUuid: undefined,
    nonSkinCancer: undefined,
    patient: undefined,
    study: undefined,
    showIntegrationPatientCreatedModal: false,
    isManualUploadRemoteMode: false,
};

const assessmentReducer = (state: any = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case types.SET_CONSENT: {
            const {
                consent,
                secondaryConsent,
                automatedDecisionConsent,
                consentGiverType,
                consentGiverFullName,
                consentGiverRelation,
            } = action.payload;

            return {
                ...state,
                consent,
                secondaryConsent,
                automatedDecisionConsent,
                consentGiverType,
                consentGiverFullName,
                consentGiverRelation,
                continueAssessment: false,
            };
        }
        case types.UPDATE_LESION_NUMBER: {
            return {
                ...state,
                captureImageWizard: {
                    currentCapturDevice: 1,
                    finished: false,
                },
                currentLesion: action.payload,
                diagnosis: undefined,
                lesion: undefined,
            };
        }
        case types.SET_STUDIES: {
            return {
                ...state,
                study: action.payload,
            };
        }
        case types.SET_DEVICES: {
            return {
                ...state,
                devices: action.payload,
            };
        }
        case types.SET_CASE: {
            return {
                ...state,
                case: action.payload,
            };
        }
        case types.CREATE_CASE_SUCCESS: {
            return {
                ...state,
                case: { ...state.case, ...action.payload },
            };
        }
        case types.CREATE_PATIENT_SUCCESS: {
            const { isIntegration } = action.payload;
            return {
                ...state,
                patient: { ...state.patient, ...action.payload },
                showIntegrationPatientCreatedModal: isIntegration,
            };
        }
        case types.SAVE_PATIENT: {
            return {
                ...state,
                patient: action.payload,
            };
        }
        case types.CREATE_LESION_SUCCESS: {
            return {
                ...state,
                lesion: action.payload,
            };
        }
        case types.CREATE_LESION_FAIL: {
            return {
                ...state,
                assessmentError: action.payload,
            };
        }
        case types.CREATE_DIAGNOSIS_SUCCESS: {
            return {
                ...state,
                diagnosis: action.payload,
            };
        }
        case types.UPDATE_DIAGNOSIS_SUCCESS: {
            return {
                ...state,
                assessmentError: undefined,
            };
        }
        case types.SET_CASE_LOCATION: {
            return {
                ...state,
                locationUuid: action.payload,
            };
        }
        case types.SET_NON_SKIN_CANCER: {
            return {
                ...state,
                nonSkinCancer: action.payload,
            };
        }
        case types.SET_MANUAL_UPLOAD_REMOTE: {
            return {
                ...state,
                isManualUploadRemoteMode: action.payload,
            };
        }
        case types.CLEAR_ASSESSMENT: {
            return {
                ...state,
                assessmentError: undefined,
                captureImageWizard: {
                    currentCapturDevice: 1,
                    finished: false,
                },
                case: undefined,
                consent: undefined,
                continueAssessment: false,
                currentLesion: 0,
                devices: undefined,
                diagnosis: undefined,
                lesion: undefined,
                locationUuid: undefined,
                nonSkinCancer: undefined,
                patient: undefined,
                study: {
                    uuid: undefined,
                },
                isManualUploadRemoteMode: false,
                consentGiverType: undefined,
                consentGiverFullName: undefined,
                consentgiverRelation: undefined,
            };
        }
        case types.CLEAR_DATA: {
            return {
                ...state,
                assessmentError: undefined,
                captureImageWizard: {
                    currentCapturDevice: 1,
                    finished: false,
                },
                case: undefined,
                consent: undefined,
                continueAssessment: false,
                currentLesion: 0,
                devices: undefined,
                diagnosis: undefined,
                lesion: undefined,
                locationUuid: undefined,
                nonSkinCancer: undefined,
                patient: undefined,
                study: {
                    uuid: undefined,
                },
                isManualUploadRemoteMode: false,
                consentGiverType: undefined,
                consentGiverFullName: undefined,
                consentgiverRelation: undefined,
            };
        }
        case types.CAPTURE_IMAGE_NEXT_STEP: {
            return {
                ...state,
                captureImageWizard: action.payload,
            };
        }
        case types.CAPTURE_IMAGE_RESET: {
            return {
                ...state,
                captureImageWizard: {
                    currentCapturDevice: 1,
                    finished: false,
                },
            };
        }
        case types.CONTINUE_ASSESSMENT: {
            const { currentLesion, case: currentCase, lesion, patient } = action.payload;
            const { nonSkinCancer } = currentCase;

            return {
                ...state,
                assessmentError: undefined,
                captureImageWizard: {
                    currentCapturDevice: 1,
                    finished: false,
                },
                case: currentCase,
                consent: Boolean(currentCase.consentDate),
                continueAssessment: true,
                currentLesion,
                diagnosis: undefined,
                lesion,
                locationUuid: currentCase.locationUuid,
                nonSkinCancer,
                patient,
                showIntegrationPatientCreatedModal: false,
                isManualUploadRemoteMode: false,
            };
        }
        case types.UPDATE_LESION_SUCCESS: {
            const { lesionUuid, lesionExclusions, lesionHistoryAnswers, response } = action.payload;
            const { case: currentCase = {} } = state;
            const { lesions = [{ exclusions: [], uuid: 0, lesionHistory: [] }] } = state.case;
            const currentLesionIndex = lesions.findIndex((lesion: any) => lesion.uuid === lesionUuid);
            const lesionsCopy = [...lesions];

            lesionsCopy.splice(currentLesionIndex, 1, {
                ...lesions[currentLesionIndex],
                ...response,
                exclusions: [...lesionExclusions],
                lesionHistory: [...lesionHistoryAnswers],
            });

            return {
                ...state,
                lesion: response,
                case: {
                    ...currentCase,
                    lesions: lesionsCopy,
                },
            };
        }
        case types.SET_CURRENT_LESION: {
            const { bodyPart, description, excluded, uuid } = action.payload;

            return {
                ...state,
                lesion: {
                    bodyPart,
                    description,
                    excluded,
                    uuid,
                },
            };
        }
        case types.TOGGLE_SHOW_INTEGRATION_PATIENT_CREATED_MODAL: {
            return {
                ...state,
                showIntegrationPatientCreatedModal: !state.showIntegrationPatientCreatedModal,
            };
        }
        case types.SET_TRANSLATOR_DATA: {
            const { translatorData } = action.payload;
            return {
                ...state,
                case: {
                    ...state.case,
                    translatorData,
                },
            };
        }
        case types.SET_CHAPERONE_DATA: {
            const { chaperoneData } = action.payload;
            return {
                ...state,
                case: {
                    ...state.case,
                    chaperoneData,
                },
            };
        }
        default:
            return state;
    }
};

export default assessmentReducer;
