import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Form, Image, InputOnChangeData, Message } from "semantic-ui-react";
import { assessmentActions } from "redux/actions";
import { getChaperoneData } from "redux/selectors/assessment";

import useAutoLogout from "hooks/useAutoLogout";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";
import CustomButton from "components/templates/Button";

import { IChaperoneData } from "model/case";
import { IErrorResponse } from "model/errors";

import * as caseService from "services/caseService";

import withModal from "HOC/withModal";

interface IChaperoneDetailsEdit {
    caseUuid: string;
    closeDetails: () => void;
}

const ChaperoneDetailsEdit: FC<IChaperoneDetailsEdit> = ({ caseUuid, closeDetails }) => {
    useAutoLogout();
    let chaperoneData = useSelector(getChaperoneData);
    if (chaperoneData?.removed) {
        chaperoneData = undefined;
    }
    const dispatch = useDispatch();
    const [chaperoneFullName, setChaperoneFullName] = useState(chaperoneData ? chaperoneData.chaperoneFullName : "");
    const [showValidationError, setShowValidationError] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const validateChaperoneData = (): boolean => !chaperoneFullName;

    const saveDetails = async (): Promise<void> => {
        if (validateChaperoneData()) {
            setShowValidationError(true);
            return;
        }
        try {
            setApiErrorResponse(null);
            const newChaperoneData: IChaperoneData = {
                chaperoneFullName,
            };
            const result = await caseService.setChaperone(caseUuid, newChaperoneData);
            dispatch(assessmentActions.setChaperoneData({ chaperoneData: result.data }));
        } catch (error) {
            setApiErrorResponse({
                message: "Setting chaperone details failed.",
            });
        }
        closeDetails();
    };

    const onChaperoneFullNameChange = (event: any, data: InputOnChangeData): void => {
        setChaperoneFullName(data.value);
    };

    const showChaperoneDataValidationError = (type: string): boolean => {
        switch (type) {
            case "chaperoneFullName": {
                return showValidationError && !chaperoneFullName;
            }
            default: {
                return false;
            }
        }
    };

    return (
        <Container className="ui segment wizzard-container">
            <Image className="chaperone-details-edit-icon" src="/images/icons/icon-person-add.svg" />
            <h3 className="chaperone-details-edit-title">Add chaperone details</h3>
            <div className="chaperone-details-edit">
                <Form className="margin-bottom-2">
                    <Form.Input
                        type="text"
                        value={chaperoneFullName}
                        label="Chaperone name"
                        id="chaperoneFullName"
                        required
                        placeholder="Enter chaperone full name"
                        onChange={onChaperoneFullNameChange}
                    />
                </Form>
                {showChaperoneDataValidationError("chaperoneFullName") && (
                    <Message error content="This field is required" />
                )}
                {apiErrorResponse ? (
                    <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
                ) : null}
            </div>
            <div className="buttons-wrapper">
                <CustomButton type="button" variant="cancel" action={closeDetails} text="Cancel" />
                <CustomButton type="button" variant="filled" action={saveDetails} text="Save details" />
            </div>
        </Container>
    );
};

export default withModal(ChaperoneDetailsEdit);
