import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledPhoneInputWrapper = styled(Box)(() => ({
    "marginTop": "32px",
    "display": "flex",
    "alignItems": "center",
    "& .MuiInputBase-root": {
        borderRadius: "0.5rem",
    },
}));

export default StyledPhoneInputWrapper;
