import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Divider, Table } from "semantic-ui-react";
import AssessmentLesions from "components/ReadyForAssessment/AssessmentLesions";
import UnableToSendForAssessmentModal from "components/ReadyForAssessment/UnableToSendForAssessmentModal";
import CustomButton from "components/templates/Button";
import ButtonContainer from "components/templates/ButtonContainer";
import ModalDialog from "components/templates/dialog/ModalDialog";
import PatientBanner from "components/templates/PatientBanner";
import NonSkinCancerLesionTable from "components/templates/NonSkinCancerLesionTable";
import { isCaseImmediateResult } from "helpers/assessment";
import { getFieldFromTemplate, getTemplate } from "helpers/template";

import { IUser } from "model/user";
import { IAssessment } from "model/assessment";
import { ICase, ILesion } from "model/case";
import { ILesionExclusion } from "model/lesionExclusion";
import { GuidanceValue, IAllocation, IOrganisation } from "model/organisation";
import { TemplateType } from "model/templateType";
import { UserRole } from "model/userRole";
import HttpStatus from "model/httpStatus";

import { history } from "App";
import { CASE_DESCRIPTION, CASE_RESULTS, HOME, LESION_LOCATOR, UPLOADED_IMAGES } from "navigation/routes";

import assessmentService from "services/assessmentService";
import * as caseService from "services/caseService";
import logoutTimeoutService from "services/logoutTimeoutService";

import TranslatorDetails from "./TranslatorDetails";
import ChaperoneDetails from "./ChaperoneDetails";

import "scss/ConfirmDialog.scss";
import "scss/Table.scss";

const INTERVAL_TIMEOUT_MS = 3000;

interface IReadyForAssessment {
    case: ICase;
    assessment: IAssessment;
    organisation: IOrganisation;
    pendingRequest: boolean;
    currentLesion: number;
    updateCurrentLesionNumber: (lesionNumber: number) => void;
    getCase: (uuid: string) => void;
    user: IUser;
}

interface IReadyForAssessmentState {
    currentLesion: number;
    assessment: IAssessment;
    caseObject: ICase;
    createLesion: boolean;
    finished: boolean;
    intervalId: number;
    lesions?: ILesion[];
    sentForAssessmentButtonDisabled: boolean;
    showExclusions: boolean;
    exclusionAdded: ILesionExclusion[];
    showSentForAssessmentModal: boolean;
    showUnableToSendForAssessmentModal: boolean;
    unableToSendForAssessmentMessage: string;
    redirectToHomePage: boolean;
}
const getUnableToSendForAssessmentMessage = (caseId) =>
    `Go back to the case list, find <strong>Case ID ${caseId}</strong> and try to send for assessment again`;

class ReadyForAssessment extends Component<IReadyForAssessment, IReadyForAssessmentState> {
    constructor(props: IReadyForAssessment) {
        super(props);
        this.state = {
            currentLesion: props.currentLesion,
            assessment: props.assessment,
            caseObject: props.case,
            showExclusions: !props.assessment.nonSkinCancer,
            createLesion: false,
            finished: false,
            intervalId: 0,
            sentForAssessmentButtonDisabled: !props.assessment.isManualUploadRemoteMode,
            exclusionAdded: [],
            showSentForAssessmentModal: false,
            showUnableToSendForAssessmentModal: false,
            unableToSendForAssessmentMessage: getUnableToSendForAssessmentMessage(props.assessment.case.caseId),
            redirectToHomePage: true,
        };
    }

    public componentDidMount() {
        this.fetchCaseData();
    }

    public componentDidUpdate(): void {
        const { exclusionAdded } = this.state;
        if (exclusionAdded.length > 0) {
            this.fetchCaseData(true, exclusionAdded);
            this.resetExclusionAdded();
        }
    }

    public componentWillUnmount(): void {
        this.stopPollingCase();
    }

    private getLesionResultTable = () => {
        const { pendingRequest, organisation } = this.props;
        const { assessment, showExclusions, currentLesion, caseObject } = this.state;
        const { isManualUploadRemoteMode } = assessment;
        const { consentData } = caseObject;
        const { lesionsLimit } = organisation;
        const isLimitAchieved = lesionsLimit ? currentLesion + 1 === lesionsLimit : false;
        const isAddLesionButtonDisabled = pendingRequest || isLimitAchieved;

        return (
            <>
                <Table celled className="assessment-table">
                    <Table.Header>
                        <Table.HeaderCell width={2}>Lesion</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Location</Table.HeaderCell>
                        {showExclusions && <Table.HeaderCell width={3}>Exclusions</Table.HeaderCell>}
                    </Table.Header>
                    <Table.Body>
                        <AssessmentLesions
                            caseObject={assessment.case}
                            organisation={organisation}
                            showExclusions={showExclusions}
                            setExclusionAdded={(lesions: ILesionExclusion[]) =>
                                this.setState({ exclusionAdded: lesions })
                            }
                            isManualUploadRemoteMode={isManualUploadRemoteMode}
                        />
                    </Table.Body>
                </Table>

                <TranslatorDetails caseUuid={caseObject.uuid} consentData={consentData} />

                <ChaperoneDetails caseUuid={caseObject.uuid} consentData={consentData} />

                {!isManualUploadRemoteMode && (
                    <>
                        <h3>
                            {isLimitAchieved
                                ? `You cannot add any more lesions to this case, the limit is ${lesionsLimit}. Send for assessment now.`
                                : "Add another lesion or send for assessment now."}
                        </h3>
                        <CustomButton
                            disabled={isAddLesionButtonDisabled}
                            variant="empty"
                            type="submit"
                            action={this.addLesion}
                            text="Add Lesion"
                        />
                    </>
                )}
            </>
        );
    };

    private getNonSkinCancerLesionResultTable = () => {
        const { assessment } = this.state;
        return <NonSkinCancerLesionTable lesion={assessment.lesion} />;
    };

    private addLesion = () => {
        const { pendingRequest, updateCurrentLesionNumber } = this.props;
        const { currentLesion } = this.state;
        if (!pendingRequest) {
            updateCurrentLesionNumber(currentLesion + 1);
            this.setState({ createLesion: true });
        }
    };

    private submit = (): void => {
        const { lesions, caseObject } = this.state;
        const { automatedDecisionConsentDate } = caseObject;

        const { pendingRequest, organisation } = this.props;
        const { showAutomatedDecisionConsent } = organisation;

        const hasMissingUrgentReferConfiguration: boolean = this.checkUrgentReferSettings();

        if (hasMissingUrgentReferConfiguration) {
            this.setState({ showUnableToSendForAssessmentModal: true });
        } else {
            const useUnconsentedAllocationConfig = showAutomatedDecisionConsent && !automatedDecisionConsentDate;

            if (!pendingRequest) {
                assessmentService
                    .finishAssessment(caseObject.uuid)
                    .then(() => {
                        if (isCaseImmediateResult(lesions || [], organisation, useUnconsentedAllocationConfig)) {
                            this.setState({ finished: true });
                        } else {
                            this.setState({ showSentForAssessmentModal: true });
                        }
                    })
                    .catch((error) => {
                        switch (error?.cause?.statusCode) {
                            case HttpStatus.FORBIDDEN: {
                                this.setState({
                                    showUnableToSendForAssessmentModal: true,
                                    redirectToHomePage: false,
                                    unableToSendForAssessmentMessage: "Please try again in 10 seconds.",
                                });
                                break;
                            }
                            default: {
                                this.setState({ showUnableToSendForAssessmentModal: true });
                            }
                        }
                    });
            }
        }
    };

    private handleOnClose = () => {
        this.setState({ finished: true });
        this.setState({ showSentForAssessmentModal: false });
    };

    private checkUrgentReferSettings = (): boolean => {
        const { organisation } = this.props;
        const { lesions } = this.state;

        const lesionWithUrgentReferExists: boolean = lesions?.some((lesion: ILesion) =>
            lesion?.result?.lesions?.some((l) => l.referralRecommendation.plainText === GuidanceValue.URGENT_REFER)
        );

        const organisationSettingsHasUrgentRefer: boolean = organisation?.allocationConfiguration?.some(
            (allocation: IAllocation) => allocation.guidanceValue === GuidanceValue.URGENT_REFER
        );

        if (lesionWithUrgentReferExists && !organisationSettingsHasUrgentRefer) {
            return true;
        }
        return false;
    };

    private checkLesionResults = (
        lesions: ILesion[],
        exclusionsUpdated?: boolean,
        addedExclusions?: ILesionExclusion[]
    ): boolean => {
        const {
            assessment: { nonSkinCancer },
        } = this.props;

        if (nonSkinCancer) {
            this.setState({ sentForAssessmentButtonDisabled: false });
            return false;
        }

        if (!exclusionsUpdated) {
            const lesionWithoutResultExist = Boolean(lesions.find((lesion: ILesion) => !lesion.result));
            this.setState({ sentForAssessmentButtonDisabled: lesionWithoutResultExist });
            if (!lesionWithoutResultExist) {
                this.stopPollingCase();
            }
            return lesionWithoutResultExist;
        }

        const exclusionUuids = lesions.map((lesion) => lesion.exclusions.map((exclusion) => exclusion.uuid)).flat();
        const exclusionsNotUpdated = addedExclusions.filter(
            (exclusion) => !exclusionUuids.includes(exclusion.exclusionUuid)
        );
        this.setState({ sentForAssessmentButtonDisabled: exclusionsNotUpdated.length > 0 });
        if (exclusionsNotUpdated.length === 0) {
            this.stopPollingCase();
        }
        return exclusionsNotUpdated.length === 0;
    };

    private getAllUserOrganisationUuids = (user: IUser): string[] => {
        const additionalOrganisationUuids =
            user.additionalOrganisationData?.length > 0
                ? user.additionalOrganisationData.map((org) => org.organisationUuid)
                : [];
        const allOrganisationUuids = [user.organisationUuid, ...additionalOrganisationUuids];
        return allOrganisationUuids;
    };

    private moveBack = () => {
        const { updateCurrentLesionNumber } = this.props;
        const { currentLesion } = this.state;
        updateCurrentLesionNumber(currentLesion - 1);
        history.push(UPLOADED_IMAGES);
    };

    public handleCloseUrgentReferModal = (): void => {
        this.setState({ showUnableToSendForAssessmentModal: false });
        if (this.state.redirectToHomePage) {
            history.push(HOME);
        }
        const caseId = this.state.assessment.case?.caseId;
        this.setState({
            unableToSendForAssessmentMessage: getUnableToSendForAssessmentMessage(caseId),
            redirectToHomePage: true,
        });
    };

    private stopPollingCase(): void {
        const { intervalId } = this.state;
        window.clearInterval(intervalId);
    }

    public fetchCaseData(exclusionsUpdated?: boolean, addedExclusions?: ILesionExclusion[]): void {
        logoutTimeoutService.stopCount();
        const {
            getCase,
            case: currentCase,
            assessment: { isManualUploadRemoteMode },
        } = this.props;
        const { exclusionAdded, lesions } = this.state;
        const { uuid, lesions: currentCaseLesions } = currentCase;
        getCase(uuid);

        if (!lesions) {
            this.setState({ sentForAssessmentButtonDisabled: true });
            caseService.getCaseAsync(uuid, ["lesions", "organisationUuid"]).then((fetchedCase) => {
                const { lesions: caseLesions } = fetchedCase;
                this.setState({ lesions: caseLesions });
            });
            this.setState({ sentForAssessmentButtonDisabled: false });
        }

        const shouldCheck =
            !isManualUploadRemoteMode &&
            this.checkLesionResults(currentCaseLesions, exclusionsUpdated, addedExclusions || []);
        if (shouldCheck || exclusionAdded.length > 0) {
            const intervalId = window.setInterval(() => {
                caseService.getCaseAsync(uuid, ["lesions", "organisationUuid"]).then((fetchedCase) => {
                    const { lesions: caseLesions } = fetchedCase;
                    this.setState({ lesions: caseLesions });
                    this.checkLesionResults(caseLesions, exclusionsUpdated, addedExclusions || []);
                });
            }, INTERVAL_TIMEOUT_MS);
            this.setState({ intervalId });
        }

        this.setState({ currentLesion: currentCaseLesions.length - 1 });
    }

    public resetExclusionAdded() {
        this.setState({ exclusionAdded: [] });
    }

    public render() {
        const { pendingRequest, organisation, user } = this.props;
        const {
            finished,
            assessment,
            sentForAssessmentButtonDisabled,
            lesions,
            createLesion,
            caseObject,
            showSentForAssessmentModal,
            showUnableToSendForAssessmentModal,
            unableToSendForAssessmentMessage,
            redirectToHomePage,
        } = this.state;
        const { showAutomatedDecisionConsent } = organisation;
        const { caseId, lesions: caseLesions, automatedDecisionConsentDate } = caseObject;

        if (createLesion) {
            return <Redirect to={`${LESION_LOCATOR}/${caseLesions.length + 1}`} />;
        }

        if (finished) {
            const useUnconsentedAllocationConfig = showAutomatedDecisionConsent && !automatedDecisionConsentDate;
            if (isCaseImmediateResult(lesions || [], organisation, useUnconsentedAllocationConfig)) {
                return <Redirect to={`${CASE_RESULTS}/${assessment.case.uuid}`} />;
            }
            return <Redirect to={HOME} />;
        }

        const isNormalFlow = !assessment.nonSkinCancer;
        const isSuperadmin = user.role === UserRole.SUPERADMIN;
        const isRemoteFlowManualUpload = assessment.isManualUploadRemoteMode;
        const shouldRenderControllsManualUpload = isSuperadmin && isRemoteFlowManualUpload;
        const allUserOrgUuids = this.getAllUserOrganisationUuids(user);

        const template = getTemplate(TemplateType.FINISH_ASSESSMENT_POPUP);
        const modalTitle = getFieldFromTemplate("title", caseObject, organisation, template);
        const modalMessage = getFieldFromTemplate("message", caseObject, organisation, template);

        return (
            <>
                <Container className="ui segment wizzard-container">
                    <PatientBanner
                        caseId={assessment.case.caseId}
                        patient={assessment.patient}
                        nonSkinCancerFlow={assessment.nonSkinCancer}
                    />

                    <h1>Ready for assessment</h1>
                    <Divider />

                    {isNormalFlow && this.getLesionResultTable()}
                    {!isNormalFlow && this.getNonSkinCancerLesionResultTable()}

                    <ButtonContainer
                        button1={<CustomButton variant="empty" type="submit" action={this.moveBack} text="< Back" />}
                        button2={
                            allUserOrgUuids.includes(caseObject.organisationUuid) ||
                            shouldRenderControllsManualUpload ? (
                                <div>
                                    {shouldRenderControllsManualUpload && (
                                        <CustomButton
                                            variant="empty"
                                            type="link"
                                            to={`${CASE_DESCRIPTION}/${caseObject.uuid}`}
                                            text="Save and close"
                                        />
                                    )}

                                    <CustomButton
                                        disabled={sentForAssessmentButtonDisabled}
                                        loading={sentForAssessmentButtonDisabled || pendingRequest}
                                        variant="filled"
                                        type="submit"
                                        action={this.submit}
                                        text="Send for assessment"
                                    />
                                </div>
                            ) : (
                                <p className="contact-text">Contact the case creator to send for assessment.</p>
                            )
                        }
                    />
                </Container>
                <UnableToSendForAssessmentModal
                    caseId={caseId}
                    organisation={organisation}
                    showModal={showUnableToSendForAssessmentModal}
                    message={unableToSendForAssessmentMessage}
                    buttonText={redirectToHomePage ? "Back to case list" : "Close"}
                    closeModal={this.handleCloseUrgentReferModal}
                />
                <ModalDialog
                    title={modalTitle}
                    open={showSentForAssessmentModal}
                    onClose={this.handleOnClose}
                    maxWidth="sm"
                    iconName="checkmark"
                    onEsc={() => {
                        this.setState({ finished: true });
                    }}
                    subtitle={
                        <div>
                            <span>{`Case ID: ${caseObject.caseId} > ${organisation.name} `}</span>
                        </div>
                    }
                    buttons={[
                        {
                            onClick: this.handleOnClose,
                            text: "Close",
                            colour: "primary",
                        },
                    ]}
                >
                    {modalMessage}
                </ModalDialog>
            </>
        );
    }
}

export default ReadyForAssessment;
