import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Divider, Grid, Menu, Segment } from "semantic-ui-react";

import AllocationComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationComponent";
import { AllocationConfigurationProvider } from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AllocationConfigProvider";
import AutomatedAllocationComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Allocation/AutomatedAllocationComponent";
import EditDetailsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/EditOrganisationDetailsComponent";
import EditExclusionsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Exclusions/EditExclusionsComponent";
import EditHistoryQuestionComponent from "components/Administration/OrganisationManagment/ViewOrganisation/HistoryQuestions/EditHistoryQuestionComponent";
import EditLocationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Locations/LocationsComponent";
import OrganisationNotificationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Notifications/OrganisationNotifications";
import PersonalData from "components/Administration/OrganisationManagment/ViewOrganisation/PersonalData/PersonalData";
import { ReviewSettingsContextProvider } from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsProvider";
import ReviewSettingsScreen from "components/Administration/OrganisationManagment/ViewOrganisation/Review/ReviewSettingsScreen";
import ReviewSettingsNscScreen from "components/Administration/OrganisationManagment/ViewOrganisation/ReviewNsc/ReviewSettingsNscScreen";
import SuborganisationsComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Suborganisations/SuborganisationsComponent";
import TemplatesComponent from "components/Administration/OrganisationManagment/ViewOrganisation/Templates/TemplatesComponent";
import Consent from "components/Administration/OrganisationManagment/ViewOrganisation/Consent";
import LoadingSpinner from "components/templates/LoadingSpinner";
import OrganisationInheritedWarning from "components/templates/OrganisationInheritedWarning";
import OverdueSettings from "components/Administration/OrganisationManagment/ViewOrganisation/Overdue";
import LandingPage from "components/Administration/OrganisationManagment/ViewOrganisation/LandingPage/LandingPage";

import { getModifiedInheritedProperties } from "helpers/organisation";

import { OrganisationMenuItems } from "model/administrationPages";
import { IHistoryQuestionType, IOverdue, IAutoAllocation, IOrganisation, IOverdueUpdate } from "model/organisation";
import { IRegistrationTexts } from "model/registration";
import { UserRole } from "model/userRole";

import { HOME } from "navigation/routes";

import organizationService from "services/organizationService";
import userService from "services/userService";

export const ORGANISATION_INHERITANCE_PROPERTY = {
    [IHistoryQuestionType.LESION]: "lesionHistoryQuestions",
    [IHistoryQuestionType.SKIN_COMPLAINT]: "skinComplaintQuestions",
    [IHistoryQuestionType.MEDICAL]: "medicalHistoryQuestions",
    [IHistoryQuestionType.NSC_MEDICAL]: "nscMedicalHistoryQuestions",
    [IHistoryQuestionType.BIOPSY]: "biopsyQuestions",
    [OrganisationMenuItems.PERSONAL_DATA]: "definedPersonalData",
    [OrganisationMenuItems.EXCLUSIONS]: "lesionExclusions",
    [OrganisationMenuItems.REVIEW_SETTINGS]: "reviewDiagnoses",
    [OrganisationMenuItems.TEMPLATES]: "templates",
    [OrganisationMenuItems.CASE_ALLOCATION]: "allocationConfiguration",
    REVIEW_MANAGEMENT_OPTIONS: "reviewManagementOptions",
    NOTIFICATION_CONFIGURATION: "notificationConfiguration",
};

const testId = "organisation-management-tab";

class OrganisationDetailsScreen extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeItem: OrganisationMenuItems.ORGANISATION_DETAILS,
            errorDetails: [],
            isError: false,
            mode: "view",
            organisation: null,
            inheritedTabs: [],
        };
    }

    public componentDidMount() {
        this.getOrganisation();
    }

    public componentDidUpdate() {
        const { params } = this.props;
        const { organisation } = this.state;

        if (organisation && params.uuid !== organisation.uuid) {
            organizationService.getOrganisationByUuid(params.uuid).then((result: IOrganisation) => {
                this.setState({
                    activeItem: OrganisationMenuItems.ORGANISATION_DETAILS,
                    organisation: result,
                    inheritedTabs: getModifiedInheritedProperties(result, true),
                });
            });
        }
    }

    private getOrganisation() {
        const { params } = this.props;

        organizationService.getOrganisationByUuid(params.uuid).then((result) => {
            this.setState({
                organisation: result,
                inheritedTabs: getModifiedInheritedProperties(result, true),
                workingDaysTemp: result.workingDays,
            });
        });
    }

    private getDetailView() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.ORGANISATION_DETAILS) {
            return (
                <EditDetailsComponent
                    organisation={organisation}
                    updateOrganisation={this.updateOrganisation}
                    setOrganisatonWorkingDays={this.setOrganisatonWorkingDays}
                />
            );
        }

        return <></>;
    }

    private setOrganisatonWorkingDays = (workingDays: string[]) => {
        this.setState((prevState) => ({
            organisation: { ...prevState.organisation, workingDays },
        }));
    };

    private setRegistrationTexts = (registrationTexts: IRegistrationTexts) => {
        this.setState((prevState) => ({
            organisation: { ...prevState.organisation, registrationTexts },
        }));
    };

    private getSuborganisations() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.SUBORGANISATIONS) {
            return <SuborganisationsComponent organisation={organisation} />;
        }

        return <></>;
    }

    private getMedicalHistory() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.MEDICAL_HISTORY) {
            const showEditButton = this.getShowEditButton(IHistoryQuestionType.MEDICAL);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditHistoryQuestionComponent
                                organisation={organisation}
                                questionType={IHistoryQuestionType.MEDICAL}
                                title="Medical History Questions"
                                historyQuestions={organisation.medicalHistoryQuestions}
                                updateOrganisation={this.updateOrganisation}
                                showEditButton={showEditButton}
                                addQuestion={this.addQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL]
                                )}
                                deleteQuestion={this.deleteQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL]
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        return <></>;
    }

    private getNSCMedicalHistory() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.NSC_MEDICAL_HISTORY) {
            const showEditButton = this.getShowEditButton(IHistoryQuestionType.NSC_MEDICAL);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditHistoryQuestionComponent
                                organisation={organisation}
                                questionType={IHistoryQuestionType.NSC_MEDICAL}
                                title="Medical History - OSC"
                                historyQuestions={organisation.nscMedicalHistoryQuestions}
                                updateOrganisation={this.updateOrganisation}
                                showEditButton={showEditButton}
                                addQuestion={this.addQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.NSC_MEDICAL]
                                )}
                                deleteQuestion={this.deleteQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.NSC_MEDICAL]
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getLesionHistory() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.LESION_HISTORY) {
            const showEditButton = this.getShowEditButton(IHistoryQuestionType.LESION);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditHistoryQuestionComponent
                                organisation={organisation}
                                questionType={IHistoryQuestionType.LESION}
                                title="Lesion History Questions"
                                historyQuestions={organisation.lesionHistoryQuestions}
                                updateOrganisation={this.updateOrganisation}
                                showEditButton={showEditButton}
                                addQuestion={this.addQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION]
                                )}
                                deleteQuestion={this.deleteQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION]
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getSkinComplaintHistory() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.SKIN_COMPLAINT_HISTORY) {
            const showEditButton = this.getShowEditButton(IHistoryQuestionType.SKIN_COMPLAINT);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditHistoryQuestionComponent
                                organisation={organisation}
                                questionType={IHistoryQuestionType.SKIN_COMPLAINT}
                                title="Skin Complaint History - OSC"
                                historyQuestions={organisation.skinComplaintQuestions}
                                updateOrganisation={this.updateOrganisation}
                                showEditButton={showEditButton}
                                addQuestion={this.addQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.SKIN_COMPLAINT]
                                )}
                                deleteQuestion={this.deleteQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.SKIN_COMPLAINT]
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getBiopsyHistory() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.BIOPSY) {
            const showEditButton = this.getShowEditButton(IHistoryQuestionType.LESION);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditHistoryQuestionComponent
                                organisation={organisation}
                                questionType={IHistoryQuestionType.BIOPSY}
                                title="Biopsy Questions"
                                historyQuestions={organisation.biopsyQuestions}
                                updateOrganisation={this.updateOrganisation}
                                showEditButton={showEditButton}
                                addQuestion={this.addQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.BIOPSY]
                                )}
                                deleteQuestion={this.deleteQuestion(
                                    ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.BIOPSY]
                                )}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getExclusions() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.EXCLUSIONS) {
            const showEditButton = this.getShowEditButton(OrganisationMenuItems.EXCLUSIONS);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditExclusionsComponent
                                title="Exclusions"
                                exclusions={organisation.lesionExclusions}
                                organisation={organisation}
                                showEditButton={showEditButton}
                                updateInheritanceData={this.updateInheritanceData}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getLocations() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.LOCATIONS) {
            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <EditLocationsComponent locations={organisation.locations} organisation={organisation} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }

        return <></>;
    }

    private getPersonalData() {
        const { activeItem, organisation } = this.state;

        const showEditButton = this.getShowEditButton(OrganisationMenuItems.PERSONAL_DATA);

        if (activeItem === OrganisationMenuItems.PERSONAL_DATA) {
            const { uuid: organisationUuid, parentUuid } = organisation;

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <PersonalData
                                showEditButton={showEditButton}
                                organisationUuid={organisationUuid}
                                parentOrganisationUuid={parentUuid}
                                updateInheritanceData={this.updateInheritanceData}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        return <></>;
    }

    private getTemplates() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.TEMPLATES) {
            const showEditButton = this.getShowEditButton(OrganisationMenuItems.TEMPLATES);

            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <TemplatesComponent
                                organisation={organisation}
                                showEditButton={showEditButton}
                                updateInheritanceData={this.updateInheritanceData}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        return <></>;
    }

    private getConsentSettings() {
        const { activeItem, organisation } = this.state;

        return (
            activeItem === OrganisationMenuItems.CONSENT && (
                <Consent organisation={organisation} updateOrganisation={this.updateOrganisation} />
            )
        );
    }

    private getLandingPageData() {
        const { activeItem, organisation } = this.state;

        return (
            activeItem === OrganisationMenuItems.LANDING_PAGE_DATA && (
                <LandingPage organisation={organisation} setRegistrationTexts={this.setRegistrationTexts} />
            )
        );
    }

    private getAllocationConfig() {
        const { activeItem, organisation } = this.state;
        const { parentUuid = undefined, uuid: organisationUuid } = organisation;

        if (activeItem === OrganisationMenuItems.CASE_ALLOCATION) {
            const showEditButton = this.getShowEditButton(OrganisationMenuItems.CASE_ALLOCATION);

            const onSave = (autoAssignConfig: IAutoAllocation) => {
                this.setState({
                    organisation: {
                        ...organisation,
                        autoAssignConfig: [autoAssignConfig],
                    },
                });
            };

            return (
                <>
                    <AllocationConfigurationProvider>
                        <AllocationComponent
                            organisationUuid={organisationUuid}
                            showEditButton={showEditButton}
                            updateInheritanceData={this.updateInheritanceData}
                            parentUuid={parentUuid}
                        />
                        <AutomatedAllocationComponent organisation={organisation} onSave={onSave} />
                    </AllocationConfigurationProvider>
                </>
            );
        }
        return <></>;
    }

    private getReviewSettings() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.REVIEW_SETTINGS) {
            const showEditButton = this.getShowEditButton(OrganisationMenuItems.REVIEW_SETTINGS);

            return (
                <ReviewSettingsContextProvider>
                    <ReviewSettingsScreen
                        organisation={organisation}
                        showEditButton={showEditButton}
                        updateInheritanceData={this.updateInheritanceData}
                    />
                </ReviewSettingsContextProvider>
            );
        }
        return <></>;
    }

    private getNscReviewSettings() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.NSC_REVIEW_SETTINGS) {
            const showEditButton = this.getShowEditButton(OrganisationMenuItems.NSC_REVIEW_SETTINGS);

            return (
                <ReviewSettingsNscScreen
                    organisation={organisation}
                    showEditButton={showEditButton}
                    updateInheritanceData={this.updateInheritanceData}
                />
            );
        }

        return <></>;
    }

    private getOverdueSettings() {
        const { activeItem, organisation } = this.state;

        if (activeItem === OrganisationMenuItems.OVERDUE_SETTINGS) {
            return <OverdueSettings onSave={this.onOverdueSettingChange} organisation={organisation} />;
        }

        return <></>;
    }

    private getNotifications() {
        const { activeItem, organisation } = this.state;

        const showEditButton = this.getShowEditButton("NOTIFICATION_CONFIGURATION");
        if (activeItem === OrganisationMenuItems.NOTIFICATIONS) {
            return (
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            <OrganisationNotificationsComponent
                                organisation={organisation}
                                showEditButton={showEditButton}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            );
        }
        return <></>;
    }

    private getMenu() {
        const { activeItem, inheritedTabs, organisation } = this.state;
        const { overdueDefinitions, nonSkinCancerAllowed, codeName } = organisation;

        return (
            <>
                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.ORGANISATION_DETAILS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.ORGANISATION_DETAILS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) =>
                            this.handleKeyPress(e, OrganisationMenuItems.ORGANISATION_DETAILS)
                        }
                        data-testid={testId}
                    >
                        Details
                    </Menu.Item>

                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.CONSENT}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.CONSENT}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.CONSENT)}
                        data-testid={testId}
                    >
                        Consent
                        {inheritedTabs[OrganisationMenuItems.CONSENT] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.CONSENT]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>

                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.CASE_ALLOCATION}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.CASE_ALLOCATION}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.CASE_ALLOCATION)}
                        data-testid={testId}
                    >
                        Case Allocation
                        {inheritedTabs[OrganisationMenuItems.CASE_ALLOCATION] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.CASE_ALLOCATION]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>

                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.LOCATIONS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.LOCATIONS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.LOCATIONS)}
                        data-testid={testId}
                    >
                        Locations
                    </Menu.Item>
                </Menu>

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.NOTIFICATIONS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.NOTIFICATIONS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.NOTIFICATIONS)}
                        data-testid={testId}
                    >
                        Notifications
                        {inheritedTabs[OrganisationMenuItems.NOTIFICATIONS] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.NOTIFICATIONS]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.TEMPLATES}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.TEMPLATES}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.TEMPLATES)}
                        data-testid={testId}
                    >
                        Templates
                        {inheritedTabs[OrganisationMenuItems.TEMPLATES] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.TEMPLATES]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.PERSONAL_DATA}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.PERSONAL_DATA}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.PERSONAL_DATA)}
                        data-testid={testId}
                    >
                        Personal Data
                        {inheritedTabs[OrganisationMenuItems.PERSONAL_DATA] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.PERSONAL_DATA]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.MEDICAL_HISTORY}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.MEDICAL_HISTORY}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.MEDICAL_HISTORY)}
                        data-testid={testId}
                    >
                        Medical History
                        {inheritedTabs[OrganisationMenuItems.MEDICAL_HISTORY] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.MEDICAL_HISTORY]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.LESION_HISTORY}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.LESION_HISTORY}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.LESION_HISTORY)}
                        data-testid={testId}
                    >
                        Lesion History
                        {inheritedTabs[OrganisationMenuItems.LESION_HISTORY] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.LESION_HISTORY]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                {nonSkinCancerAllowed && (
                    <>
                        <Menu pointing stackable secondary compact>
                            <Menu.Item
                                active={activeItem === OrganisationMenuItems.NSC_MEDICAL_HISTORY}
                                onClick={this.handleMenuClick}
                                action={OrganisationMenuItems.NSC_MEDICAL_HISTORY}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent) =>
                                    this.handleKeyPress(e, OrganisationMenuItems.NSC_MEDICAL_HISTORY)
                                }
                                data-testid={testId}
                            >
                                Medical History - OSC
                                {inheritedTabs[OrganisationMenuItems.NSC_MEDICAL_HISTORY] ? (
                                    <OrganisationInheritedWarning
                                        organisationInheritedProperties={
                                            inheritedTabs[OrganisationMenuItems.NSC_MEDICAL_HISTORY]
                                        }
                                        isProperties
                                    />
                                ) : null}
                            </Menu.Item>
                        </Menu>
                        <Menu pointing stackable secondary compact>
                            <Menu.Item
                                active={activeItem === OrganisationMenuItems.SKIN_COMPLAINT_HISTORY}
                                onClick={this.handleMenuClick}
                                action={OrganisationMenuItems.SKIN_COMPLAINT_HISTORY}
                                tabIndex={0}
                                onKeyDown={(e: KeyboardEvent) =>
                                    this.handleKeyPress(e, OrganisationMenuItems.SKIN_COMPLAINT_HISTORY)
                                }
                                data-testid={testId}
                            >
                                Skin Complaint History - OSC
                                {inheritedTabs[OrganisationMenuItems.SKIN_COMPLAINT_HISTORY] ? (
                                    <OrganisationInheritedWarning
                                        organisationInheritedProperties={
                                            inheritedTabs[OrganisationMenuItems.SKIN_COMPLAINT_HISTORY]
                                        }
                                        isProperties
                                    />
                                ) : null}
                            </Menu.Item>
                        </Menu>
                    </>
                )}

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.BIOPSY}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.BIOPSY}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.BIOPSY)}
                        data-testid={testId}
                    >
                        Biopsy
                        {inheritedTabs[OrganisationMenuItems.BIOPSY] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.BIOPSY]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>
                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.EXCLUSIONS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.EXCLUSIONS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.EXCLUSIONS)}
                        data-testid={testId}
                    >
                        Exclusions
                        {inheritedTabs[OrganisationMenuItems.EXCLUSIONS] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.EXCLUSIONS]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>
                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.REVIEW_SETTINGS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.REVIEW_SETTINGS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.REVIEW_SETTINGS)}
                        data-testid={testId}
                    >
                        Review Settings
                        {inheritedTabs[OrganisationMenuItems.REVIEW_SETTINGS] ? (
                            <OrganisationInheritedWarning
                                organisationInheritedProperties={inheritedTabs[OrganisationMenuItems.REVIEW_SETTINGS]}
                                isProperties
                            />
                        ) : null}
                    </Menu.Item>
                </Menu>

                {nonSkinCancerAllowed && (
                    <Menu pointing stackable secondary compact>
                        <Menu.Item
                            active={activeItem === OrganisationMenuItems.NSC_REVIEW_SETTINGS}
                            onClick={this.handleMenuClick}
                            action={OrganisationMenuItems.NSC_REVIEW_SETTINGS}
                            tabIndex={0}
                            onKeyDown={(e: KeyboardEvent) =>
                                this.handleKeyPress(e, OrganisationMenuItems.NSC_REVIEW_SETTINGS)
                            }
                            data-testid={testId}
                        >
                            Review Settings - OSC
                            {inheritedTabs[OrganisationMenuItems.NSC_REVIEW_SETTINGS] ? (
                                <OrganisationInheritedWarning
                                    organisationInheritedProperties={
                                        inheritedTabs[OrganisationMenuItems.NSC_REVIEW_SETTINGS]
                                    }
                                    isProperties
                                />
                            ) : null}
                        </Menu.Item>
                    </Menu>
                )}

                {overdueDefinitions?.length && userService.checkUserHasRole([UserRole.SUPERADMIN]) ? (
                    <Menu pointing stackable secondary compact>
                        <Menu.Item
                            active={activeItem === OrganisationMenuItems.OVERDUE_SETTINGS}
                            onClick={this.handleMenuClick}
                            action={OrganisationMenuItems.OVERDUE_SETTINGS}
                            tabIndex={0}
                            onKeyDown={(e: KeyboardEvent) =>
                                this.handleKeyPress(e, OrganisationMenuItems.OVERDUE_SETTINGS)
                            }
                            data-testid={testId}
                        >
                            Overdue Settings
                            {inheritedTabs[OrganisationMenuItems.OVERDUE_SETTINGS] ? (
                                <OrganisationInheritedWarning
                                    organisationInheritedProperties={
                                        inheritedTabs[OrganisationMenuItems.OVERDUE_SETTINGS]
                                    }
                                    isProperties
                                />
                            ) : null}
                        </Menu.Item>
                    </Menu>
                ) : null}

                <Menu pointing stackable secondary compact>
                    <Menu.Item
                        active={activeItem === OrganisationMenuItems.SUBORGANISATIONS}
                        onClick={this.handleMenuClick}
                        action={OrganisationMenuItems.SUBORGANISATIONS}
                        tabIndex={0}
                        onKeyDown={(e: KeyboardEvent) => this.handleKeyPress(e, OrganisationMenuItems.SUBORGANISATIONS)}
                        data-testid={testId}
                    >
                        Suborganisations
                    </Menu.Item>
                </Menu>

                {codeName && userService.checkUserHasRole([UserRole.SUPERADMIN]) ? (
                    <Menu pointing stackable secondary compact>
                        <Menu.Item
                            active={activeItem === OrganisationMenuItems.LANDING_PAGE_DATA}
                            onClick={this.handleMenuClick}
                            action={OrganisationMenuItems.LANDING_PAGE_DATA}
                            tabIndex={0}
                            onKeyDown={(e: KeyboardEvent) =>
                                this.handleKeyPress(e, OrganisationMenuItems.LANDING_PAGE_DATA)
                            }
                            data-testid={testId}
                        >
                            Landing Page
                        </Menu.Item>
                    </Menu>
                ) : null}
            </>
        );
    }

    private handleMenuClick = (event: any, object: any) => {
        this.setState({ activeItem: object.action });
    };

    private handleKeyPress = (event: KeyboardEvent, action: string): void => {
        if (event.key === "Enter") {
            this.setState({ activeItem: action });
        }
    };

    private updateOrganisation = (result: any) => {
        const { organisation } = this.state;
        const copiedOragnisation = { ...organisation };
        delete copiedOragnisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.LESION]];
        delete copiedOragnisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.MEDICAL]];
        delete copiedOragnisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[IHistoryQuestionType.EXCLUSION]];
        const updatedOrganisation = { ...copiedOragnisation, ...result };
        this.setState({ organisation: updatedOrganisation });
    };

    private updateInheritanceData = (property: string) => {
        const { organisation } = this.state;
        const copiedOrganisation = { ...organisation };
        const { inheritanceData = {} } = { ...copiedOrganisation };
        const copiedInheritanceData = { ...inheritanceData };
        delete copiedInheritanceData[property];
        copiedOrganisation.inheritanceData = copiedInheritanceData;
        this.setState({ organisation: copiedOrganisation });
    };

    private getShowEditButton = (questionType: string) => {
        const { organisation } = this.state;
        const showEditButton = !!organisation.inheritanceData[ORGANISATION_INHERITANCE_PROPERTY[questionType]];

        return showEditButton;
    };

    private onOverdueSettingChange = (overdueUpdate: IOverdueUpdate & { uuid: string }) => {
        const { organisation } = this.state;
        const { overdueDefinitions } = organisation;
        const { uuid } = overdueUpdate;

        const updatedOverdueIndex = overdueDefinitions.findIndex((item: IOverdue) => item.uuid === uuid);

        if (updatedOverdueIndex < 0) {
            return;
        }

        const [...overdueDefinitionsUpdated] = overdueDefinitions;
        overdueDefinitionsUpdated[updatedOverdueIndex] = {
            ...overdueDefinitions[updatedOverdueIndex],
            ...overdueUpdate,
        };

        this.setState((prevState) => ({
            ...prevState,
            organisation: {
                ...organisation,
                overdueDefinitions: overdueDefinitionsUpdated,
            },
        }));
    };

    private addQuestion = (key: string) => (edit: any) => {
        const { organisation } = this.state;

        this.setState({
            organisation: {
                ...organisation,
                [key]: [...organisation[key], edit],
            },
        });
    };

    private deleteQuestion = (key: string) => (uuid: string) => {
        const { organisation } = this.state;

        this.setState({
            organisation: {
                ...organisation,
                [key]: organisation[key].filter((item: any) => item.uuid !== uuid),
            },
        });
    };

    public render() {
        const { organisation } = this.state;

        if (!userService.checkUserHasRole([UserRole.ADMIN, UserRole.SA_ADMIN, UserRole.SUPERADMIN])) {
            return <Redirect to={HOME} />;
        }

        if (organisation === null) {
            return <LoadingSpinner />;
        }

        return (
            <Segment>
                <h2>{organisation.name}</h2>
                <Divider />
                <div>
                    {this.getMenu()}
                    {this.getDetailView()}
                    {this.getConsentSettings()}
                    {this.getSuborganisations()}
                    {this.getLocations()}
                    {this.getMedicalHistory()}
                    {this.getNSCMedicalHistory()}
                    {this.getLesionHistory()}
                    {this.getSkinComplaintHistory()}
                    {this.getBiopsyHistory()}
                    {this.getExclusions()}
                    {this.getPersonalData()}
                    {this.getAllocationConfig()}
                    {this.getTemplates()}
                    {this.getReviewSettings()}
                    {this.getNscReviewSettings()}
                    {this.getNotifications()}
                    {this.getOverdueSettings()}
                    {this.getLandingPageData()}
                </div>
            </Segment>
        );
    }
}

export default OrganisationDetailsScreen;
