import { FC } from "react";
import { Image } from "semantic-ui-react";

import CustomButton from "components/templates/Button";

import withModal from "HOC/withModal";

interface IChaperoneDetailsRemovalConfirmation {
    onClose: () => void;
    onConfirm: () => void;
}

const ChaperoneDetailsRemovalConfirmation: FC<IChaperoneDetailsRemovalConfirmation> = ({ onClose, onConfirm }) => (
    <div>
        <Image className="remove-chaperone-details-confirmation-icon" src="/images/icons/icon-round-check.svg" />
        <h3 className="remove-chaperone-details-confirmation-title">Remove chaperone details?</h3>
        <div className="buttons-wrapper">
            <CustomButton type="button" variant="cancel" action={onClose} text="No, keep it" />
            <CustomButton type="button" variant="filled" action={onConfirm} text="Yes, remove it" />
        </div>
    </div>
);

export default withModal(ChaperoneDetailsRemovalConfirmation);
