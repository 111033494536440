import { FC, useState } from "react";
import { List, ListItem } from "semantic-ui-react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getChaperoneData } from "redux/selectors/assessment";
import { assessmentActions } from "redux/actions";
import { handleKeyDown } from "helpers/input";

import CustomMessage, { CustomMessageType } from "components/templates/CustomMessage";

import { IConsentData } from "model/case";
import { IErrorResponse } from "model/errors";

import * as caseService from "services/caseService";

import ChaperoneDetailsEdit from "./ChaperoneDetailsEdit";
import ChaperoneDetailsRemovalConfirmation from "./ChaperoneDetailsRemovalConfirmation";

interface IChaperoneDetails {
    caseUuid: string;
    consentData?: IConsentData;
}

const ChaperoneDetails: FC<IChaperoneDetails> = ({ caseUuid, consentData }) => {
    const chaperoneData = useSelector(getChaperoneData);
    const dispatch = useDispatch();
    const [showChaperoneDetailsEdit, setShowChaperoneDetailsEdit] = useState(false);
    const [showChaperoneDetailsDelete, setShowChaperoneDetailsDelete] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState<IErrorResponse>(null);

    const deleteDetails = async (): Promise<void> => {
        try {
            setApiErrorResponse(null);
            await caseService.removeChaperone(caseUuid);
            dispatch(assessmentActions.setChaperoneData({ chaperoneData: undefined }));
        } catch (error) {
            setApiErrorResponse({
                message: "Removing chaperone details failed.",
            });
        }
        setShowChaperoneDetailsDelete(false);
    };

    const getDetails = (): React.ReactNode => {
        if (!chaperoneData || chaperoneData.removed) {
            return (
                <span>Was a chaperone involved during the image-taking process? If yes, please add the details</span>
            );
        }
        return (
            <div>
                <span>A chaperone was involved:</span>
                <List bulleted>
                    <ListItem key="chaperoneFullName">
                        <span>Chaperone name: {chaperoneData.chaperoneFullName}</span>
                    </ListItem>
                </List>
            </div>
        );
    };

    const getActions = (): React.ReactNode => {
        if (!chaperoneData || chaperoneData.removed) {
            return (
                <span
                    className="text-button text-button__no-underline chaperone-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowChaperoneDetailsEdit(true);
                        })
                    }
                    onClick={() => {
                        setShowChaperoneDetailsEdit(true);
                    }}
                >
                    Add details
                </span>
            );
        }
        return (
            <>
                (
                <span
                    className="text-button text-button__no-underline chaperone-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowChaperoneDetailsEdit(true);
                        })
                    }
                    onClick={() => {
                        setShowChaperoneDetailsEdit(true);
                    }}
                >
                    Edit
                </span>{" "}
                |{" "}
                <span
                    className="text-button text-button__no-underline chaperone-details-info-link"
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            setShowChaperoneDetailsDelete(true);
                        })
                    }
                    onClick={() => {
                        setShowChaperoneDetailsDelete(true);
                    }}
                >
                    Delete
                </span>
                )
            </>
        );
    };

    const chaperoneDetails = getDetails();
    const chaperoneActions = getActions();

    // Chaperone data management is independent from extended consent data, but it has been agreed to introduce them together and use consent data existence as flag for in flight cases.
    return consentData ? (
        <div>
            {apiErrorResponse ? (
                <CustomMessage type={CustomMessageType.ERROR} message={apiErrorResponse.message} />
            ) : null}
            <div className="chaperone-details">
                <InfoOutlinedIcon className="chaperone-details-info-icon" />
                <span className="chaperone-details-content">{chaperoneDetails}</span>
                <span className="chaperone-details-actions">{chaperoneActions}</span>
            </div>
            <ChaperoneDetailsEdit
                show={showChaperoneDetailsEdit}
                closeOnOverlay={false}
                caseUuid={caseUuid}
                closeDetails={() => setShowChaperoneDetailsEdit(false)}
            />
            <ChaperoneDetailsRemovalConfirmation
                show={showChaperoneDetailsDelete}
                closeOnOverlay={false}
                onClose={() => setShowChaperoneDetailsDelete(false)}
                onConfirm={deleteDetails}
            />
        </div>
    ) : null;
};

export default ChaperoneDetails;
